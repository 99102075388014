import qs from 'querystring'

import React, { useEffect, useState, useContext } from 'react'

import axios from 'axios'

import { routeBase } from '../../constants/environments.constants'

import BackDrop from 'components/BackDrop'
import { SHOPIFY_START_AUTHORIZE, SHOPIFY_AUTH_CALLBACK } from 'constants/urls'
import useAuthContext from 'contextApi/AuthContext'

export default function ShopifyPage() {
  const { logout } = useContext(useAuthContext)
  const [isLoading, setIsLoading] = useState(false)
  const headersShopify = routeBase[process.env.REACT_APP_ENVIRONMENT].routeShopify
  useEffect(() => {
    logout('login')
    const query = qs.parse(window.location.search)

    const data = {
      hmac: query['?hmac'],
      shop: query.shop,
      timestamp: query.timestamp,
      redirectUrl: SHOPIFY_AUTH_CALLBACK,
    }
    setIsLoading(true)
    axios
      .post(SHOPIFY_START_AUTHORIZE, data, {
        headers: {
          SCOrigen: headersShopify.SCOrigen,
          country: headersShopify.country,
          'Ocp-Apim-Subscription-Key': headersShopify.subscriptionId,
        },
      })
      .then((response) => {
        setIsLoading(false)
        if (response.data.redirectTo) {
          window.location.href = response.data.redirectTo
        }
      })
      .catch(function (error) {
        setIsLoading(false)
        console.error(error)
      })
  }, [])
  return <BackDrop open={isLoading} />
}
