/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from 'react'

import { Button, Divider, Grid, IconButton, Typography, Tooltip } from '@material-ui/core'
import CachedIcon from '@material-ui/icons/Cached'
import PriorityHighRoundedIcon from '@material-ui/icons/PriorityHighRounded'
import _ from 'lodash'
import moment from 'moment'

import useStyles from '../products.styles'

import BackDrop from 'components/BackDrop'
import CardErrorImages from 'components/CardErrorImages/CardErrorImages'
import IconErrorImages from 'components/IconErrorImages/IconErrorImages'
import { CheckIcon, CloseIcon, DeleteIcon, BoxIcon } from 'components/icons/personalizedIcons'
import TooltipErrorImages from 'components/TooltipErrorImages/TooltipErrorImages'
import useCustomDialogAlert from 'components/useCustomDialogAlert'
import useAuthContext from 'contextApi/AuthContext'
import { useProduct } from 'contextApi/ProductContext'
import { processImage } from 'services/imageProcessor/ImageProcesor.service'

const ImagesProduct = ({
  isProductStepper,
  saveAndPublish,
  variation,
  setVariation,
  selectApprovalMethod,
  setImageProcessor,
  setImagesListVariant,
}) => {
  const classes = useStyles()
  const showDialogAlert = useCustomDialogAlert()
  const { product, setProduct, handleNext, handleBack, handleCancel, isApprove, isModified } =
    useProduct()
  const { userLoggedin } = useContext(useAuthContext)
  const [imagesList, setImagesList] = useState([])
  const [imagesProcessed, setImagesProcessed] = useState({
    imagesProcessedList: undefined,
    imagesProcessedDisable: true,
    imagesProcessedErrorVisible: '',
    imagesProcessedLoading: false,
  })

  const handleOptionsOpen = (index) => {
    let newImagesProduct = []
    if (isProductStepper) {
      newImagesProduct = [...product.images]
    } else {
      newImagesProduct = [...variation.images]
    }

    const item = newImagesProduct.find((p, i) => i === index)

    if (item !== undefined) newImagesProduct.find((p, i) => i === index).showOptions = true
    if (isProductStepper) {
      setProduct({
        ...product,
        images: newImagesProduct,
      })
    } else {
      setVariation({ ...variation, images: newImagesProduct })
    }
  }

  const handleOptionsClose = (index) => {
    let newImagesProduct = []

    if (isProductStepper) {
      newImagesProduct = [...product.images]
    } else {
      newImagesProduct = [variation.images]
    }
    const item = newImagesProduct.find((p, i) => i === index)
    if (item !== undefined) newImagesProduct.find((p, i) => i === index).showOptions = false
    if (isProductStepper) {
      setProduct({
        ...product,
        images: newImagesProduct,
      })
    } else {
      item.showOptions = false
    }
  }
  const isEmpty = (obj) => {
    return Object.keys(obj ?? {}).length === 0
  }
  const handleImageProcessing = async (
    images,
    listImagesOk,
    sku,
    replaceIndex,
    onSuccess,
    onError
  ) => {
    try {
      setImagesProcessed((prev) => ({
        ...prev,
        imagesProcessedLoading: true,
      }))
      const res = await processImage(
        images,
        listImagesOk,
        imagesProcessed.imagesProcessedList,
        sku,
        product?.seller?.sellerId ?? userLoggedin.sellerId
      )
      if (res) {
        if (images.length < 1) return
        const newImagesProcessedList = res
        if (replaceIndex !== undefined) {
          const lastItem = newImagesProcessedList.files.pop()
          newImagesProcessedList.files.splice(replaceIndex, 1, lastItem)
        }
        const newImages = images.map((image, index) => {
          if (!isEmpty(res.files[index])) {
            let idProduct = null
            if (
              index === 0 &&
              isProductStepper &&
              product.images.length > 0 &&
              !product.images[0].isValid
            ) {
              idProduct = product.images[index].id
            } else if (
              index === 0 &&
              !isProductStepper &&
              variation.images.length > 0 &&
              !variation.images[0].isValid
            ) {
              idProduct = variation.images[index].id
            }
            if (idProduct) {
              return {
                ...image,
                src: res.files[index].url,
                isValid: true,
                id: idProduct,
              }
            }
            return { ...image, src: res.files[index].url, isValid: true }
          }
          return image
        })
        if (isProductStepper) {
          setProduct({
            ...product,
            images: newImages,
          })
        } else {
          setVariation({
            ...variation,
            images: newImages,
          })
        }

        setImagesProcessed((prev) => ({
          ...prev,
          imagesProcessedList: newImagesProcessedList,
        }))
        if (setImageProcessor) setImageProcessor(newImagesProcessedList)
        onSuccess()
      }
    } catch (error) {
      showDialogAlert(
        true,
        '¡Advertencia!',
        'Ha ocurrido un error al procesar las imágenes, por favor intente nuevamente. Si el problema persiste, comuniquese con su administrador.',
        'Cerrar'
      )
      onError()
    } finally {
      setImagesProcessed((prev) => ({
        ...prev,
        imagesProcessedLoading: false,
      }))
    }
  }

  const resetInputs = () => {
    document.querySelectorAll('input[type="file"]').forEach((input) => {
      input.value = ''
    })
  }

  const deleteImage = async (index) => {
    if (isProductStepper) {
      product.images.splice(index, 1)
    } else {
      const temp = { ...variation }
      temp.image = null
      temp.images.splice(index, 1)
      setVariation(temp)
    }
    const images = [...imagesList]
    images.splice(index, 1)
    if (imagesProcessed.imagesProcessedList)
      imagesProcessed.imagesProcessedList.files.splice(index, 1)
    setImagesList(images)
    if (setImagesListVariant) setImagesListVariant(images)
    handleOptionsClose(index)
  }

  const forImagesUpload = async (e, replaceIndex) => {
    setImagesProcessed((prev) => ({
      ...prev,
      imagesProcessedErrorVisible: '',
    }))
    if (product.type === 'simple') {
      if (product.warehouses[0].warehouseId === 0) {
        showDialogAlert(true, '¡Advertencia!', `Se debe ingresar primero la bodega`, 'Cerrar')
        return false
      }
    }
    const imagesFile = [...e.target.files]
    resetInputs()
    const listImagesOk = []
    let imagesNotLoaded = ''
    let imageExtensions = ''

    imagesFile.forEach((file, index) => {
      const FileMB = file.size / 1024 ** 2
      if (!/\.(jpg|png|jpeg|webp)$/i.test(file.name)) {
        imageExtensions += `${file.name}, `
      }
      if (Math.round(FileMB) <= 1) {
        const date = moment().format()
        const imageNew = {
          id: '',
          name: file.name,
          src: URL.createObjectURL(file),
          file,
          dateCreated: date,
          dateModified: date,
          alt: file.name,
          isPrincipal: replaceIndex === 0 || imagesList.length === 0 ? index === 0 : false,
        }
        listImagesOk.push(imageNew)
      } else {
        imagesNotLoaded += `${file.name}, `
      }
    })

    if (imagesNotLoaded !== '') {
      showDialogAlert(
        true,
        '¡Advertencia!',
        `La(s) imagen(es) ${imagesNotLoaded} deben tener máximo 1 MB de peso cada una`,
        'Cerrar'
      )
      return false
    }

    if (imageExtensions !== '') {
      showDialogAlert(
        true,
        '¡Advertencia!',
        `La(s) imagen(es) ${imageExtensions} deben tener extensión jpg, png o jpeg`,
        'Cerrar'
      )
      return false
    }

    const totalImagesToUpload =
      replaceIndex !== undefined ? product.images.length : imagesFile.length + product.images.length
    const newImagesList =
      replaceIndex !== undefined ? [...imagesList] : imagesList.concat(listImagesOk)
    if (replaceIndex !== undefined) {
      const [replacementImage] = listImagesOk
      newImagesList.splice(replaceIndex, 1, replacementImage)
    }
    if (isProductStepper) {
      if (imagesFile.length <= 6 && product.images.length <= 6 && totalImagesToUpload <= 6) {
        // setImagesList(newImagesList)
        setProduct({
          ...product,
          images: newImagesList,
        })
        if (newImagesList.length > 0) {
          setImagesProcessed((prev) => ({
            ...prev,
            imagesProcessedLoading: true,
          }))
          await handleImageProcessing(
            newImagesList,
            listImagesOk,
            product.sku,
            replaceIndex,
            () => {
              setImagesList(newImagesList)
            },
            () => {
              setImagesList(imagesList)
            }
          )
          setImagesProcessed((prev) => ({
            ...prev,
            imagesProcessedLoading: false,
          }))
          return true
        }
        return false
      }
      if (replaceIndex === undefined) {
        showDialogAlert(
          true,
          '¡Advertencia!',
          `Solo se puede almacenar máximo 6 imágenes`,
          'Cerrar'
        )
        return false
      }
    }

    setImagesList(newImagesList)
    if (newImagesList.length > 0) {
      setImagesProcessed((prev) => ({
        ...prev,
        imagesProcessedLoading: true,
      }))
      await handleImageProcessing(
        newImagesList,
        listImagesOk,
        product.sku,
        replaceIndex,
        () => {
          setImagesList(newImagesList)
        },
        () => {
          setImagesList(imagesList)
        }
      )
      setImagesProcessed((prev) => ({
        ...prev,
        imagesProcessedLoading: false,
      }))
    }
  }

  const clickImage = () => {
    document.getElementById(`upload-Image`).click()
  }

  const replaceImage = (index) => {
    document.getElementById(`replace-image-${index}`).click()
  }

  const validateImages = (images) => {
    if (!images) return []
    return images.map((image) => {
      if (image.isValid === null && image.processingResponse === null) {
        return { ...image, isValid: true }
      }
      return image
    })
  }

  const firstAndUniqueValidation = (position) => position !== 0 || imagesList.length <= 1

  useEffect(() => {
    setImagesProcessed((prev) => {
      const filteredFiles = (imagesProcessed.imagesProcessedList?.files || []).filter(
        (item) => item.codeIssue !== undefined && item.codeIssue !== 0
      )

      return {
        ...prev,
        imagesProcessedDisable: filteredFiles.length > 0,
      }
    })
  }, [imagesProcessed.imagesProcessedList?.files, imagesList])

  useEffect(() => {
    const componentImages = validateImages(isProductStepper ? product.images : variation.images)
    setImagesList(componentImages)
    if (isProductStepper) {
      setProduct({
        ...product,
        images: componentImages,
      })
    } else {
      setVariation({
        ...variation,
        images: componentImages,
      })
    }
  }, [isProductStepper])

  return (
    <>
      <Grid className={classes.productContaine}>
        {!isApprove && (
          <>
            <Grid className={classes.divLoadImages}>
              <Typography className={classes.titleManageImages}>Gestionar imágenes</Typography>
              <Grid container direction="column" className={classes.divAddUrl}>
                <p style={{ fontFamily: 'Red Hat Display Regular', fontSize: 16 }}>
                  {isProductStepper ? (
                    <>
                      Agrega las imágenes para tu producto, sube hasta
                      <span style={{ fontFamily: 'Red Hat Display Bold', fontSize: 16 }}>
                        {' '}
                        6 imágenes, teniendo en cuenta lo siguiente:
                      </span>
                      <div
                        style={{
                          marginTop: '20px',
                        }}
                      >
                        <ol
                          style={{
                            fontFamily: 'Red Hat Display Regular',
                            fontSize: 16,
                            textAlign: 'left',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '5px',
                          }}
                        >
                          <li>
                            1. La primera imagen debe de ir en fondo blanco y el producto centrado,
                            las demás pueden tener cualquier fondo.
                          </li>
                          <li>2. Las imágenes no deben tener contenido sugestivo.</li>
                          <li>3. Las imágenes no deben tener contenido explicito.</li>
                          <li>4. Las imágenes no deben tener contenido violento.</li>
                          <li>
                            5. Las imágenes deben tener una buena resolución mínima requerida.
                          </li>
                          <li>6. Los formatos permitidos son PNG, JPG, JPEG y WebP.</li>
                        </ol>
                      </div>
                    </>
                  ) : (
                    <>Agrega las imágenes para tu producto</>
                  )}
                </p>

                <input
                  accept=".png,.jpg,.jpeg,.webp"
                  className={classes.input}
                  onChange={(e) => forImagesUpload(e)}
                  id="upload-Image"
                  type="file"
                  multiple={isProductStepper}
                  hidden
                />
                <div className={classes.buttonContainer}>
                  <Button
                    onClick={() => clickImage()}
                    className={classes.btnBackgroundPrimary}
                    color="secondary"
                    variant="contained"
                  >
                    Agregar imágenes
                  </Button>
                </div>
              </Grid>
            </Grid>
            <Divider orientation="vertical" flexItem />
          </>
        )}

        <Grid className={classes.divListImages}>
          <div style={{ width: '90%' }}>
            <Typography className={classes.titleManageImages}>
              {`${isProductStepper ? 'Lista de imágenes' : 'Imagen variación'}`}
            </Typography>
            {isProductStepper ? (
              <>
                <p style={{ fontFamily: 'Red Hat Display Bold', fontSize: 16 }}>
                  Te recomendamos{' '}
                  <span style={{ fontFamily: 'Red Hat Display Regular', fontSize: 16 }}>
                    que las imágenes deben tener mínimo de tamaño de 700 x 700 píxeles y un máximo
                    de tamaño de 2000 x 2000 píxeles, con un peso máximo de 1 MB.
                  </span>
                </p>
              </>
            ) : (
              <>
                <p style={{ fontFamily: 'Red Hat Display Bold', fontSize: 16 }}>
                  Te recomendamos{' '}
                  <span style={{ fontFamily: 'Red Hat Display Regular', fontSize: 16 }}>
                    que las imágenes deben tener mínimo de tamaño de 700 x 700 píxeles y un máximo
                    de tamaño de 2000 x 2000 píxeles, con un peso máximo de 1 MB.
                  </span>
                </p>
              </>
            )}
          </div>
          <div className={classes.rootListIndividual}>
            <BackDrop open={imagesProcessed.imagesProcessedLoading} />
            <Grid container className={classes.containerImages}>
              {imagesList
                ? imagesList.map((imgInfo, index) => {
                    return (
                      <>
                        <Grid
                          container
                          className={classes.cardImages}
                          onMouseEnter={() => handleOptionsOpen(index)}
                          onMouseLeave={() => handleOptionsClose(index)}
                        >
                          {(imgInfo?.isValid === false ||
                            imgInfo?.processingResponse?.errors[0]?.code === '27') && (
                            <IconErrorImages>
                              <div
                                style={{
                                  backgroundColor:
                                    imgInfo?.processingResponse?.errors[0]?.code === '27'
                                      ? '#FF763A'
                                      : '#F35B69',
                                  width: '30px',
                                  height: '30px',
                                  borderRadius: '100%',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <IconButton size="small">
                                  {imgInfo?.processingResponse?.errors[0]?.code === '27' ? (
                                    <PriorityHighRoundedIcon />
                                  ) : (
                                    <CloseIcon />
                                  )}
                                </IconButton>
                              </div>
                            </IconErrorImages>
                          )}
                          {(imgInfo?.isValid === true || imgInfo?.isValid === null) && (
                            <IconErrorImages>
                              <div
                                style={{
                                  backgroundColor: '#3AD399',
                                  width: '30px',
                                  height: '30px',
                                  borderRadius: '100%',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <IconButton size="small">
                                  <CheckIcon />
                                </IconButton>
                              </div>
                            </IconErrorImages>
                          )}

                          {imagesProcessed.imagesProcessedList && (
                            <>
                              {imagesProcessed.imagesProcessedList.hasIssues ? (
                                imagesProcessed.imagesProcessedList.files.map((item, key) => (
                                  <>
                                    {Object.keys(item).length !== 0 && (
                                      <div key={key}>
                                        {item.codeIssue === 0 ? (
                                          <>
                                            {key === index && (
                                              <IconErrorImages>
                                                <div
                                                  style={{
                                                    backgroundColor: '#3AD399',
                                                    width: '30px',
                                                    height: '30px',
                                                    borderRadius: '100%',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                  }}
                                                >
                                                  <IconButton size="small">
                                                    <CheckIcon />
                                                  </IconButton>
                                                </div>
                                              </IconErrorImages>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            {key === index && Object.keys(item).length !== 0 && (
                                              <IconErrorImages>
                                                <div
                                                  style={{
                                                    backgroundColor:
                                                      imgInfo?.processingResponse?.errors[0]
                                                        ?.code === '27'
                                                        ? '#FF763A'
                                                        : '#F35B69',
                                                    width: '30px',
                                                    height: '30px',
                                                    borderRadius: '100%',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                  }}
                                                >
                                                  <IconButton size="small">
                                                    {imgInfo?.processingResponse?.errors[0]
                                                      ?.code === '27' ? (
                                                      <PriorityHighRoundedIcon />
                                                    ) : (
                                                      <CloseIcon />
                                                    )}
                                                  </IconButton>
                                                </div>
                                              </IconErrorImages>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    )}
                                  </>
                                ))
                              ) : (
                                <>
                                  {imagesProcessed.imagesProcessedList.files.map((item, key) => (
                                    <>
                                      {Object.keys(item).length !== 0 && key === index && (
                                        <IconErrorImages>
                                          <div
                                            key={key}
                                            style={{
                                              backgroundColor: '#3AD399',
                                              width: '30px',
                                              height: '30px',
                                              borderRadius: '100%',
                                              display: 'flex',
                                              justifyContent: 'center',
                                              alignItems: 'center',
                                            }}
                                          >
                                            <IconButton size="small">
                                              <CheckIcon />
                                            </IconButton>
                                          </div>
                                        </IconErrorImages>
                                      )}
                                    </>
                                  ))}
                                </>
                              )}
                            </>
                          )}
                          <Grid className={classes.cardImageEmpty}>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '10px',
                                width: '100%',
                                opacity:
                                  imagesProcessed.imagesProcessedErrorVisible === index ||
                                  imagesProcessed.imagesProcessedErrorVisible === ''
                                    ? 1
                                    : 0.5,
                              }}
                            >
                              <img
                                alt="Imagen del producto"
                                className={classes.cardImgTop}
                                src={
                                  imgInfo?.src
                                    ? imgInfo?.src
                                    : imgInfo[0]?.src
                                    ? imgInfo[0]?.src
                                    : ''
                                }
                                style={{ height: 190, width: 190, display: 'block' }}
                                data-holder-rendered="true"
                              />
                              <div
                                style={{
                                  width: '100%',
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                }}
                              >
                                <Typography className={classes.textNewImageEllipsis}>
                                  {imgInfo?.alt || 'imagen seleccionada'}
                                </Typography>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'end',
                                    alignItems: 'end',
                                  }}
                                >
                                  {imagesProcessed.imagesProcessedList && (
                                    <>
                                      {imagesProcessed.imagesProcessedList.hasIssues ? (
                                        imagesProcessed.imagesProcessedList.files.map(
                                          (item, key) => (
                                            <>
                                              {Object.keys(item).length !== 0 && index === key && (
                                                <div key={key}>
                                                  {item.codeIssue === 0 ? (
                                                    <>
                                                      {key === index && (
                                                        <Grid container spacing={2}>
                                                          <Grid item>
                                                            <input
                                                              accept=".png,.jpg,.jpeg,.webp"
                                                              className={classes.input}
                                                              onChange={(e) =>
                                                                forImagesUpload(e, index)
                                                              }
                                                              id={`replace-image-${index}`}
                                                              type="file"
                                                              hidden
                                                            />
                                                            <Tooltip title="Reemplazar">
                                                              <IconButton
                                                                size="small"
                                                                onClick={() => replaceImage(index)}
                                                              >
                                                                <CachedIcon fontSize="small" />
                                                              </IconButton>
                                                            </Tooltip>
                                                          </Grid>
                                                          {firstAndUniqueValidation(index) && (
                                                            <Grid item>
                                                              <Tooltip title="Eliminar">
                                                                <IconButton
                                                                  size="small"
                                                                  onClick={() => deleteImage(index)}
                                                                >
                                                                  <DeleteIcon />
                                                                </IconButton>
                                                              </Tooltip>
                                                            </Grid>
                                                          )}
                                                        </Grid>
                                                      )}
                                                    </>
                                                  ) : (
                                                    <>
                                                      {key === index && (
                                                        <p
                                                          onClick={() =>
                                                            setImagesProcessed((prev) => ({
                                                              ...prev,
                                                              imagesProcessedErrorVisible:
                                                                imagesProcessed.imagesProcessedErrorVisible ===
                                                                  '' ||
                                                                imagesProcessed.imagesProcessedErrorVisible !==
                                                                  index
                                                                  ? index
                                                                  : '',
                                                            }))
                                                          }
                                                          style={{
                                                            fontFamily: 'Red Hat Display SemiBold',
                                                            fontSize: 14,
                                                            color:
                                                              imgInfo?.processingResponse?.errors[0]
                                                                ?.code === '27'
                                                                ? '#FF763A'
                                                                : '#F35B69',
                                                            cursor: 'pointer',
                                                          }}
                                                        >
                                                          {imgInfo?.processingResponse?.errors[0]
                                                            ?.code === '27'
                                                            ? 'Imágen en revisión'
                                                            : 'Errores identificados'}
                                                        </p>
                                                      )}
                                                    </>
                                                  )}
                                                </div>
                                              )}
                                              {Object.keys(item).length === 0 && index === key && (
                                                <>
                                                  {imgInfo?.isValid === false ? (
                                                    <p
                                                      onClick={() =>
                                                        setImagesProcessed((prev) => ({
                                                          ...prev,
                                                          imagesProcessedErrorVisible:
                                                            imagesProcessed.imagesProcessedErrorVisible ===
                                                              '' ||
                                                            imagesProcessed.imagesProcessedErrorVisible !==
                                                              index
                                                              ? index
                                                              : '',
                                                        }))
                                                      }
                                                      style={{
                                                        fontFamily: 'Red Hat Display SemiBold',
                                                        fontSize: 14,
                                                        color:
                                                          imgInfo?.processingResponse?.errors[0]
                                                            ?.code === '27'
                                                            ? '#FF763A'
                                                            : '#F35B69',
                                                        cursor: 'pointer',
                                                      }}
                                                    >
                                                      {imgInfo?.processingResponse?.errors[0]
                                                        ?.code === '27'
                                                        ? 'Imágen en revisión'
                                                        : 'Errores identificados'}
                                                    </p>
                                                  ) : (
                                                    <Grid container spacing={2}>
                                                      <Grid item>
                                                        <input
                                                          accept=".png,.jpg,.jpeg,.webp"
                                                          className={classes.input}
                                                          onChange={(e) =>
                                                            forImagesUpload(e, index)
                                                          }
                                                          id={`replace-image-${index}`}
                                                          type="file"
                                                          hidden
                                                        />
                                                        <Tooltip title="Reemplazar">
                                                          <IconButton
                                                            size="small"
                                                            onClick={() => replaceImage(index)}
                                                          >
                                                            <CachedIcon fontSize="small" />
                                                          </IconButton>
                                                        </Tooltip>
                                                      </Grid>
                                                      {firstAndUniqueValidation(index) && (
                                                        <Grid item>
                                                          <Tooltip title="Eliminar">
                                                            <IconButton
                                                              size="small"
                                                              onClick={() => deleteImage(index)}
                                                            >
                                                              <DeleteIcon />
                                                            </IconButton>
                                                          </Tooltip>
                                                        </Grid>
                                                      )}
                                                    </Grid>
                                                  )}
                                                </>
                                              )}
                                            </>
                                          )
                                        )
                                      ) : (
                                        <>
                                          {imgInfo?.isValid === false ||
                                          imgInfo?.processingResponse?.errors[0]?.code === '27' ? (
                                            <p
                                              onClick={() =>
                                                setImagesProcessed((prev) => ({
                                                  ...prev,
                                                  imagesProcessedErrorVisible:
                                                    imagesProcessed.imagesProcessedErrorVisible ===
                                                      '' ||
                                                    imagesProcessed.imagesProcessedErrorVisible !==
                                                      index
                                                      ? index
                                                      : '',
                                                }))
                                              }
                                              style={{
                                                fontFamily: 'Red Hat Display SemiBold',
                                                fontSize: 14,
                                                color:
                                                  imgInfo?.processingResponse?.errors[0]?.code ===
                                                  '27'
                                                    ? '#FF763A'
                                                    : '#F35B69',
                                                cursor: 'pointer',
                                              }}
                                            >
                                              {imgInfo?.processingResponse?.errors[0]?.code === '27'
                                                ? 'Imágen en revisión'
                                                : 'Errores identificados'}
                                            </p>
                                          ) : (
                                            <Grid container spacing={2}>
                                              <Grid item>
                                                <input
                                                  accept=".png,.jpg,.jpeg,.webp"
                                                  className={classes.input}
                                                  onChange={(e) => forImagesUpload(e, index)}
                                                  id={`replace-image-${index}`}
                                                  type="file"
                                                  hidden
                                                />
                                                <Tooltip title="Reemplazar">
                                                  <IconButton
                                                    size="small"
                                                    onClick={() => replaceImage(index)}
                                                  >
                                                    <CachedIcon fontSize="small" />
                                                  </IconButton>
                                                </Tooltip>
                                              </Grid>
                                              {firstAndUniqueValidation(index) && (
                                                <Grid item>
                                                  <Tooltip title="Eliminar">
                                                    <IconButton
                                                      size="small"
                                                      onClick={() => deleteImage(index)}
                                                    >
                                                      <DeleteIcon />
                                                    </IconButton>
                                                  </Tooltip>
                                                </Grid>
                                              )}
                                            </Grid>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                  {!imagesProcessed.imagesProcessedList && (
                                    <>
                                      {imgInfo?.isValid === false ? (
                                        <p
                                          onClick={() =>
                                            setImagesProcessed((prev) => ({
                                              ...prev,
                                              imagesProcessedErrorVisible:
                                                imagesProcessed.imagesProcessedErrorVisible ===
                                                  '' ||
                                                imagesProcessed.imagesProcessedErrorVisible !==
                                                  index
                                                  ? index
                                                  : '',
                                            }))
                                          }
                                          style={{
                                            fontFamily: 'Red Hat Display SemiBold',
                                            fontSize: 14,
                                            color:
                                              imgInfo?.processingResponse?.errors[0]?.code === '27'
                                                ? '#FF763A'
                                                : '#F35B69',
                                            cursor: 'pointer',
                                          }}
                                        >
                                          {imgInfo?.processingResponse?.errors[0]?.code === '27'
                                            ? 'Imágen en revisión'
                                            : 'Errores identificados'}
                                        </p>
                                      ) : (
                                        <Grid container spacing={2}>
                                          <Grid item>
                                            <input
                                              accept=".png,.jpg,.jpeg,.webp"
                                              className={classes.input}
                                              onChange={(e) => forImagesUpload(e, index)}
                                              id={`replace-image-${index}`}
                                              type="file"
                                              hidden
                                            />
                                            <Tooltip title="Reemplazar">
                                              <IconButton
                                                size="small"
                                                onClick={() => replaceImage(index)}
                                              >
                                                <CachedIcon fontSize="small" />
                                              </IconButton>
                                            </Tooltip>
                                          </Grid>
                                          {firstAndUniqueValidation(index) && (
                                            <Grid item>
                                              <Tooltip title="Eliminar">
                                                <IconButton
                                                  size="small"
                                                  onClick={() => deleteImage(index)}
                                                >
                                                  <DeleteIcon />
                                                </IconButton>
                                              </Tooltip>
                                            </Grid>
                                          )}
                                        </Grid>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </Grid>
                          {imagesProcessed.imagesProcessedErrorVisible === index &&
                            imagesProcessed.imagesProcessedList &&
                            imagesProcessed.imagesProcessedList.hasIssues &&
                            imagesProcessed.imagesProcessedList.files.map((item, key) => (
                              <>
                                {Object.keys(item).length !== 0 && (
                                  <div key={key}>
                                    {item.codeIssue !== 0 && index === key && (
                                      <TooltipErrorImages>
                                        <div
                                          style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '7px',
                                          }}
                                        >
                                          <ol>
                                            <li
                                              style={{
                                                fontFamily: 'Red Hat Display SemiBold',
                                                fontSize: 14,
                                                marginLeft: '5px',
                                              }}
                                            >
                                              ● {item.issue}
                                            </li>
                                          </ol>
                                          <div
                                            style={{
                                              display: 'flex',
                                              gap: '16px',
                                              justifyContent: 'end',
                                              alignItems: 'center',
                                            }}
                                          >
                                            {firstAndUniqueValidation(index) && (
                                              <button
                                                type="button"
                                                style={{
                                                  fontFamily: 'Red Hat Display SemiBold',
                                                  fontSize: 14,
                                                  color: '#5d44ff',
                                                  cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                  setImagesProcessed((prev) => ({
                                                    ...prev,
                                                    imagesProcessedErrorVisible: '',
                                                  }))
                                                  deleteImage(index)
                                                }}
                                              >
                                                Eliminar
                                              </button>
                                            )}
                                            <input
                                              accept=".png,.jpg,.jpeg,.webp"
                                              className={classes.input}
                                              onChange={(e) => forImagesUpload(e, index)}
                                              id={`replace-image-${index}`}
                                              type="file"
                                              hidden
                                            />
                                            <Button
                                              className={classes.btnCardErrorProcessedImage}
                                              variant="contained"
                                              color="secondary"
                                              onClick={() => {
                                                replaceImage(index)
                                              }}
                                            >
                                              Cambiar imagen
                                            </Button>
                                          </div>
                                        </div>
                                      </TooltipErrorImages>
                                    )}
                                  </div>
                                )}
                              </>
                            ))}
                          {imagesProcessed.imagesProcessedErrorVisible === index &&
                            imgInfo?.isValid === false && (
                              <TooltipErrorImages>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '7px',
                                  }}
                                >
                                  <ol>
                                    <li
                                      style={{
                                        fontFamily: 'Red Hat Display SemiBold',
                                        fontSize: 14,
                                        marginLeft: '5px',
                                      }}
                                    >
                                      ●{' '}
                                      {imgInfo?.processingResponse?.errors[0]?.message &&
                                      imgInfo?.processingResponse?.errors[0]?.code === '27'
                                        ? 'Tu imágen está siendo procesada, por favor espera unos minutos y refresca la página.'
                                        : imgInfo?.processingResponse?.errors[0]?.code === '3001'
                                        ? 'Ha ocurrido un error al cargar su imágen, por favor intente nuevamente. Si el problema persiste, comuniquese con su administrador.'
                                        : imgInfo?.processingResponse?.errors[0]?.message ??
                                          'Ha ocurrido un error al cargar su imágen, por favor intente nuevamente. Si el problema persiste, comuniquese con su administrador.'}
                                    </li>
                                  </ol>
                                  <div
                                    style={{
                                      display: 'flex',
                                      gap: '16px',
                                      justifyContent: 'end',
                                      alignItems: 'center',
                                    }}
                                  >
                                    {firstAndUniqueValidation(index) && (
                                      <button
                                        type="button"
                                        style={{
                                          fontFamily: 'Red Hat Display SemiBold',
                                          fontSize: 14,
                                          color: '#5d44ff',
                                          cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                          setImagesProcessed((prev) => ({
                                            ...prev,
                                            imagesProcessedErrorVisible: '',
                                          }))
                                          deleteImage(index)
                                        }}
                                      >
                                        Eliminar
                                      </button>
                                    )}
                                    <input
                                      accept=".png,.jpg,.jpeg,.webp"
                                      className={classes.input}
                                      onChange={(e) => forImagesUpload(e, index)}
                                      id={`replace-image-${index}`}
                                      type="file"
                                      hidden
                                    />
                                    <Button
                                      className={classes.btnCardErrorProcessedImage}
                                      variant="contained"
                                      color="secondary"
                                      onClick={() => {
                                        replaceImage(index)
                                      }}
                                    >
                                      Cambiar imagen
                                    </Button>
                                  </div>
                                </div>
                              </TooltipErrorImages>
                            )}
                        </Grid>
                        {imagesProcessed.imagesProcessedErrorVisible === index &&
                          imagesProcessed.imagesProcessedList &&
                          imagesProcessed.imagesProcessedList.hasIssues &&
                          imagesProcessed.imagesProcessedList.files.map((item, key) => (
                            <>
                              {Object.keys(item).length !== 0 && (
                                <div key={key}>
                                  {item.codeIssue !== 0 && index === key && (
                                    <CardErrorImages>
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          gap: '7px',
                                        }}
                                      >
                                        <ol>
                                          <li
                                            style={{
                                              fontFamily: 'Red Hat Display SemiBold',
                                              fontSize: 14,
                                              marginLeft: '5px',
                                            }}
                                          >
                                            ● {item.issue}
                                          </li>
                                        </ol>
                                        <div
                                          style={{
                                            display: 'flex',
                                            gap: '20px',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                          }}
                                        >
                                          {firstAndUniqueValidation(index) && (
                                            <button
                                              type="button"
                                              style={{
                                                fontFamily: 'Red Hat Display SemiBold',
                                                fontSize: 14,
                                                color: '#5d44ff',
                                                cursor: 'pointer',
                                              }}
                                              onClick={() => {
                                                setImagesProcessed((prev) => ({
                                                  ...prev,
                                                  imagesProcessedErrorVisible: '',
                                                }))
                                                deleteImage(index)
                                              }}
                                            >
                                              Eliminar
                                            </button>
                                          )}
                                          <input
                                            accept=".png,.jpg,.jpeg,.webp"
                                            className={classes.input}
                                            onChange={(e) => forImagesUpload(e, index)}
                                            id={`replace-image-${index}`}
                                            type="file"
                                            hidden
                                          />
                                          <Button
                                            className={classes.btnCardErrorProcessedImage}
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => {
                                              replaceImage(index)
                                            }}
                                          >
                                            Cambiar imagen
                                          </Button>
                                        </div>
                                      </div>
                                    </CardErrorImages>
                                  )}
                                </div>
                              )}
                            </>
                          ))}
                        {imagesProcessed.imagesProcessedErrorVisible === index &&
                          imgInfo?.isValid === false && (
                            <CardErrorImages>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  gap: '7px',
                                }}
                              >
                                <ol>
                                  <li
                                    style={{
                                      fontFamily: 'Red Hat Display SemiBold',
                                      fontSize: 14,
                                      marginLeft: '5px',
                                    }}
                                  >
                                    ●{' '}
                                    {imgInfo?.processingResponse?.errors[0]?.message &&
                                    imgInfo?.processingResponse?.errors[0]?.code === '27'
                                      ? 'Tu imágen está siendo procesada, por favor espera unos minutos y refresca la página.'
                                      : imgInfo?.processingResponse?.errors[0]?.code === '3001'
                                      ? 'Ha ocurrido un error al cargar su imágen, por favor intente nuevamente. Si el problema persiste, comuniquese con su administrador.'
                                      : imgInfo?.processingResponse?.errors[0]?.message ??
                                        'Ha ocurrido un error al cargar su imágen, por favor intente nuevamente. Si el problema persiste, comuniquese con su administrador.'}
                                  </li>
                                </ol>
                                <div
                                  style={{
                                    display: 'flex',
                                    gap: '20px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                                >
                                  {firstAndUniqueValidation(index) && (
                                    <button
                                      type="button"
                                      style={{
                                        fontFamily: 'Red Hat Display SemiBold',
                                        fontSize: 14,
                                        color: '#5d44ff',
                                        cursor: 'pointer',
                                      }}
                                      onClick={() => {
                                        setImagesProcessed((prev) => ({
                                          ...prev,
                                          imagesProcessedErrorVisible: '',
                                        }))
                                        deleteImage(index)
                                      }}
                                    >
                                      Eliminar
                                    </button>
                                  )}
                                  <input
                                    accept=".png,.jpg,.jpeg,.webp"
                                    className={classes.input}
                                    onChange={(e) => forImagesUpload(e, index)}
                                    id={`replace-image-${index}`}
                                    type="file"
                                    hidden
                                  />
                                  <Button
                                    className={classes.btnCardErrorProcessedImage}
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => {
                                      replaceImage(index)
                                    }}
                                  >
                                    Cambiar imagen
                                  </Button>
                                </div>
                              </div>
                            </CardErrorImages>
                          )}
                      </>
                    )
                  })
                : null}
              {_.times(6 - imagesList.length, () => {
                return (
                  <Grid className={classes.cardImageEmpty}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      <IconButton size="small">
                        <BoxIcon />
                      </IconButton>

                      <Typography className={classes.textNewImage}>Imagen del producto</Typography>
                    </div>
                  </Grid>
                )
              })}
            </Grid>
          </div>
        </Grid>
      </Grid>
      {isProductStepper ? (
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          className={classes.gridButtonsImages}
        >
          <Button
            className={classes.btnBackgroundPrimary}
            variant="contained"
            color="secondary"
            onClick={handleBack}
          >
            Anterior
          </Button>

          <Button className={classes.btnBackgroundError} onClick={handleCancel}>
            {!isApprove ? 'Cancelar' : 'No hacer nada'}
          </Button>
          {product.type === 'variable' ? (
            <Button
              className={classes.btnBackgroundPrimary}
              variant="contained"
              color="secondary"
              onClick={handleNext}
              disabled={imagesProcessed.imagesProcessedDisable}
            >
              Continuar
            </Button>
          ) : (
            <>
              {isModified && (
                <Button
                  className={classes.btnBackgroundError}
                  variant="contained"
                  color="secondary"
                  onClick={() => selectApprovalMethod(1)}
                >
                  Rechazar
                </Button>
              )}
              <Button
                className={classes.btnBackgroundPrimary}
                variant="contained"
                color="secondary"
                disabled={imagesProcessed.imagesProcessedDisable}
                onClick={(e) => {
                  if (isApprove) {
                    selectApprovalMethod(0)
                  } else {
                    saveAndPublish(e)
                  }
                }}
              >
                {!isApprove ? 'Guardar' : 'Aprobar producto'}
              </Button>
            </>
          )}
        </Grid>
      ) : null}
    </>
  )
}

export default ImagesProduct
