/* eslint-disable no-nested-ternary */
/* eslint-disable no-bitwise */
import React, { useState } from 'react'

import DateFnsUtils from '@date-io/date-fns'
import {
  Button,
  Checkbox,
  DialogActions,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
} from '@material-ui/core'
import { HelpOutline } from '@material-ui/icons'
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { es } from 'date-fns/locale'
import { useForm } from 'react-hook-form'

import InputField from 'components/InputField'
import dateFormatUtil from 'utils/date.utils'
import useStyles from 'views/Products/products.styles'

const SaleUsageInfo = ({ info, handleBack, handleNext }) => {
  const [date, setDate] = useState({
    initialDate: new Date().toISOString(),
    finalDate: info.finalDate ? info.finalDate : null,
  })
  const [status, setStatus] = useState(info.status ? 1 : 0)
  const [isCumulative, setIsCumulative] = useState(info ? info.isCumulative : false)
  const {
    handleSubmit,
    errors,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
  })
  const classes = useStyles()

  const onSubmit = (data) => {
    handleNext({
      ...data,
      initialDate: dateFormatUtil(date.initialDate),
      finalDate: dateFormatUtil(date.finalDate),
      isCumulative: Boolean(isCumulative),
      status: Boolean(status),
    })
  }
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction="row" spacing={3}>
          <Grid item xs={6}>
            <InputField
              label="Fecha inicial"
              error={errors.initialDate && errors.initialDate.message}
            >
              <DateTimePicker
                autoOk
                fullWidth
                name="initialDate"
                variant="inline"
                inputVariant="outlined"
                minDate={info.initialDate ? undefined : new Date()}
                onChange={(initDate) => {
                  setDate({ ...date, initialDate: initDate })
                }}
                value={date.initialDate}
              />
            </InputField>
          </Grid>
          <Grid item xs={6}>
            <InputField label="Fecha final" error={errors.finalDate && errors.finalDate.message}>
              <DateTimePicker
                autoOk
                fullWidth
                name="finalDate"
                variant="inline"
                inputVariant="outlined"
                minDate={date.initialDate}
                onChange={(finalDate) => {
                  setDate({ ...date, finalDate: finalDate })
                }}
                value={date.finalDate}
                helperText="La fecha final debe ser mayor a la fecha inicial"
              />
            </InputField>
          </Grid>
        </Grid>
        <InputField label="Estado" error={errors.status && errors.status.message}>
          <Select
            name="status"
            value={status}
            variant="outlined"
            fullWidth
            onChange={(event) => {
              setStatus(event.target.value)
            }}
          >
            <MenuItem value={1}>Activo</MenuItem>
            <MenuItem value={0}>Inactivo</MenuItem>
          </Select>
        </InputField>
        <Grid container direction="row">
          <Grid item xs={6}>
            <FormControlLabel
              label="Acumulable con otras promociones"
              control={
                <Checkbox
                  color="primary"
                  name="isCumulative"
                  value={isCumulative}
                  defaultChecked={isCumulative}
                  onChange={(e) => setIsCumulative(e.target.checked)}
                />
              }
            />
            <Tooltip
              title="Al marcar esta opción, la promoción será redimible durante el proceso de compra junto a otras promociones que compartan esta característica."
              interactive
              placement="right"
              arrow
              classes={{
                tooltipPlacementRight: classes.tooltipPlacementRight,
              }}
            >
              <IconButton aria-label="delete" className={classes.margin} size="small">
                <HelpOutline fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <DialogActions>
          <Button onClick={handleBack} className={classes.btnBackgroundErrorPriceSmall}>
            Atrás
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className={classes.btnNewProductsmall}
            disabled={!isValid || !date.finalDate || date.finalDate < date.initialDate}
          >
            Siguiente
          </Button>
        </DialogActions>
      </form>
    </MuiPickersUtilsProvider>
  )
}

export default SaleUsageInfo
