// eslint-disable-next-line import/prefer-default-export
export const routeBase = {
  qa: {
    url: 'https://qa-lpg-dnc-back-apigateway-k8.luegopago.com/sellercenter',
    urlMicroservicesProducts: 'https://api-co-nop-internal.sistecreditocloud.com/products/v1/Product',
    apiManagementOrigin: 'Qa',
    apiManagementSubscriptionKey: '',
    productsKey: '2341ff8615cbfc09e71b0adf3198f189a51e97c04ea6f3c2e6e2f43990ccf600',
    productsIv: '6252833d1fff0b40821284abbc4169b8',
    urlUserManagement: 'https://qa-lpg-dnc-back-apigateway-k8.luegopago.com/usermanagement',
    urlMicroservicesOrders: 'https://qa-lpg-dnc-back-apigateway-k8.luegopago.com/orders/api/v1/Order',
    CONCILIATOR_URL: 'https://qa-lpg-dnc-back-apigateway-k8.luegopago.com/checkout/api/checkout',
    CONCILIATOR_USER: 'Qa',
    CONCILIATOR_PASSWORD: '2d6afed9-f9f5-483a-a987-c8ae1024d09f',
    marketingCampaign: 'https://qa-lpg-dnc-back-apigateway-k8.luegopago.com/sellermarketing-campaigns',
    MARKETING_CAMPAING_USER: 'Qa',
    MARKETING_CAMPAING_PASSWORD: '2d6afed9-f9f5-483a-a987-c8ae1024d09f',
    routeFront: 'https://qa-lpg-rea-front-sellercenter-k8.luegopago.com',
    isMaintenance: 'true',
    routeShopify: {
      url: 'https://devapi.credinet.co/luegopago/integration',
      SCOrigen: 'qa',
      country: 'co',
      subscriptionId: '',
    },
    coupons: 'https://qa-lpg-dnc-back-apigateway-k8.luegopago.com/api/coupon',
    returns: 'https://qa-lpg-dnc-back-apigateway-k8.luegopago.com/return-orders/api/',
    sales: 'https://qa-lpg-dnc-back-apigateway-k8.luegopago.com/api/sales',
    imageProcessor: 'https://qa-lpg-dnc-back-apigateway-k8.luegopago.com/image-processor/api/Process/ProcessImages',
    imageProcessorApiKey: 'ebcf9b48-1c4c-4409-af03-dc2ee3b8fe76',
    PAYMENTS: {
      PAYMENTS_API: 'https://qa-lpg-dnc-back-apigateway-k8.luegopago.com/payments',
      PAYMENTS_OCP_APIM_SUBSCRIPTION_KEY: 'b0dc8eb7924540e1913ab262b8500721',
      PAYMENTS_API_KEY: 'f37131eb-ac5b-40ed-8c2e-22465e616ae5',
      SC_ORIGIN: 'Qa',
    },
    TRAVEL: {
      TRAVEL_API: 'https://qa-lpg-dnc-back-apigateway-k8.luegopago.com/travel',
      USER: 'Qa',
      PASSWORD: '093970a1-5ce7-47ad-97cf-508cfb42d040',
    },
    NOTIFICATION_CONFIGURATOR: 'https://luegopago.blob.core.windows.net/luegopago-uploads/NotificationConfigurator/QA/NotificationConfigurator.js',
    googleApiKey: 'AIzaSyD315GmuVfjOE8CgDEkY_kiYV-JD2RT1DM',
    integrations: {
      token: 'THVlZ29QYWdv.Y2QyMzk2MmQtNTY2Ni00M2MzLWE2NmYtMzMwZmMwMDczODRi',
      secretKey: 'AervN2HSP8ay9WYGz3ZAzevvtW',
    },
    hideReturns: false,
    hideSales: false,
    categorizer: 'https://qa-lpg-dnc-back-apigateway-k8.luegopago.com/categorizer',
    dashboard: 'https://luegopago.blob.core.windows.net/luegopago-uploads/ReportsDashboard/QA/dashboard-bundle-iife.js',
    soat: 'https://qa-lpg-dnc-back-apigateway-k8.luegopago.com/soat/api/v1',
    payments: 'https://qa-lpg-dnc-back-apigateway-k8.luegopago.com/Payments/api/v2.0',
    soatApiKey: '',
    soatApiIV: '',
    cloudflareIp: 'https://www.cloudflare.com/cdn-cgi/trace',
  },
  stag: {
    url: '',
    urlMicroservicesProducts: '',
    apiManagementOrigin: '',
    apiManagementSubscriptionKey: '',
    productsKey: '',
    productsIv: '',
    urlUserManagement: '',
    urlMicroservicesOrders: '',
    CONCILIATOR_URL: '',
    CONCILIATOR_USER: '',
    CONCILIATOR_PASSWORD: '',
    marketingCampaign: '',
    MARKETING_CAMPAING_USER: '',
    MARKETING_CAMPAING_PASSWORD: '',
    routeFront: '',
    isMaintenance: 'true',
    routeShopify: {
      url: '',
      SCOrigen: 'Staging',
      country: 'co',
      subscriptionId: '',
    },
    coupons: '',
    returns: '',
    sales: '',
    imageProcessor: '',
    imageProcessorApiKey: '',
    PAYMENTS: {
      PAYMENTS_API: '',
      PAYMENTS_OCP_APIM_SUBSCRIPTION_KEY: '',
      PAYMENTS_API_KEY: '',
      SC_ORIGIN: 'Staging',
    },
    TRAVEL: {
      TRAVEL_API: '',
      USER: '',
      PASSWORD: '',
    },
    NOTIFICATION_CONFIGURATOR: '',
    googleApiKey: '',
    integrations: {
      token: '',
      secretKey: '',
    },
    hideReturns: false,
    hideSales: false,
    categorizer: '',
    dashboard: '',
    soat: '',
    payments: '',
    soatApiKey: '',
    soatApiIV: '',
    cloudflareIp: '',
  },
  prod: {
    url: '',
    urlMicroservicesProducts: '',
    apiManagementOrigin: '',
    apiManagementSubscriptionKey: '',
    productsKey: '',
    productsIv: '',
    urlUserManagement: '',
    urlMicroservicesOrders: '',
    CONCILIATOR_URL: '',
    CONCILIATOR_USER: '',
    CONCILIATOR_PASSWORD: '',
    marketingCampaign: '',
    MARKETING_CAMPAING_USER: '',
    MARKETING_CAMPAING_PASSWORD: '',
    routeFront: '',
    isMaintenance: 'true',
    routeShopify: {
      url: '',
      SCOrigen: 'Production',
      country: 'co',
      subscriptionId: '',
    },
    coupons: '',
    returns: '',
    sales: '',
    imageProcessor: '',
    imageProcessorApiKey: '',
    PAYMENTS: {
      PAYMENTS_API: '',
      PAYMENTS_OCP_APIM_SUBSCRIPTION_KEY: '',
      PAYMENTS_API_KEY: '',
      SC_ORIGIN: 'Production',
    },
    TRAVEL: {
      TRAVEL_API: '',
      USER: '',
      PASSWORD: '',
    },
    NOTIFICATION_CONFIGURATOR: '',
    googleApiKey: '',
    integrations: {
      token: '',
      secretKey: '',
    },
    hideReturns: Boolean('' === 'true'),
    hideSales: Boolean('' === 'true'),
    categorizer: '',
    dashboard: '',
    soat: '',
    payments: '',
    soatApiKey: '',
    soatApiIV: '',
    cloudflareIp: '',
  },

  default: {
    url: 'https://qa-lpg-dnc-back-apigateway-k8.luegopago.com/sellercenter',
    urlMicroservicesProducts:
      'https://api-co-nop-internal.sistecreditocloud.com/products/v1/Product',
    apiManagementOrigin: 'Qa',
    apiManagementSubscriptionKey: '',
    productsKey: '2341ff8615cbfc09e71b0adf3198f189a51e97c04ea6f3c2e6e2f43990ccf600',
    productsIv: '6252833d1fff0b40821284abbc4169b8',
    urlMicroservicesOrders:
      'https://qa-lpg-dnc-back-apigateway-k8.luegopago.com/orders/api/v1/Order',
    urlUserManagement: 'https://qa-lpg-dnc-back-apigateway-k8.luegopago.com/usermanagement',
    CONCILIATOR_URL: 'http://localhost:3000/api/checkout',
    CONCILIATOR_USER: 'Qa',
    CONCILIATOR_PASSWORD: '2d6afed9-f9f5-483a-a987-c8ae1024d09f',
    marketingCampaign:
      'https://qa-lpg-dnc-back-apigateway-k8.luegopago.com/sellermarketing-campaigns',
    MARKETING_CAMPAING_USER: 'Development',
    MARKETING_CAMPAING_PASSWORD: '2d6afed9-f9f5-483a-a987-c8ae1024d09f',
    routeFront: 'https://qa-luegopago-front-sellercenter.luegopago.com',
    isMaintenance: false,
    routeShopify: {
      url: 'https://qa-luegopago-seller-integration-back-api.luegopago.com',
    },
    coupons: 'https://qa-lpg-dnc-back-apigateway-k8.luegopago.com/api/coupon',
    returns: 'https://qa-lpg-dnc-back-apigateway-k8.luegopago.com/return-orders/api/',
    sales: 'https://qa-lpg-dnc-back-apigateway-k8.luegopago.com/api/sales',
    imageProcessor:
      'https://qa-lpg-dnc-back-apigateway-k8.luegopago.com/image-processor/api/Process/ProcessImages',
    imageProcessorApiKey: 'ebcf9b48-1c4c-4409-af03-dc2ee3b8fe76',
    PAYMENTS: {
      PAYMENTS_API: 'https://devapi.credinet.co/luegopago/pagos',
      PAYMENTS_OCP_APIM_SUBSCRIPTION_KEY: 'b0dc8eb7924540e1913ab262b8500721',
      PAYMENTS_API_KEY: 'd8701b30-3f3d-40d1-896e-df2e8b97abc1',
      SC_ORIGIN: 'Development',
    },
    TRAVEL: {
      TRAVEL_API: 'https://qa-lpg-dnc-back-apigateway-k8.luegopago.com/travel',
      USER: 'Qa',
      PASSWORD: '093970a1-5ce7-47ad-97cf-508cfb42d040',
    },
    NOTIFICATION_CONFIGURATOR:
      'https://luegopago.blob.core.windows.net/luegopago-uploads/NotificationConfigurator/DEV/NotificationConfigurator.js',
    googleApiKey: 'AIzaSyD315GmuVfjOE8CgDEkY_kiYV-JD2RT1DM',
    integrations: {
      token: 'THVlZ29QYWdv.Y2QyMzk2MmQtNTY2Ni00M2MzLWE2NmYtMzMwZmMwMDczODRi',
      secretKey: 'AervN2HSP8ay9WYGz3ZAzevvtW',
    },
    hideReturns: false,
    hideSales: false,
    categorizer: 'https://qa-lpg-dnc-back-apigateway-k8.luegopago.com/categorizer',
    dashboard:
      'https://luegopago.blob.core.windows.net/luegopago-uploads/ReportsDashboard/DEV/dashboard-bundle-iife.js',
    soat: 'https://qa-lpg-dnc-back-apigateway-k8.luegopago.com/soat/api/v1',
    payments: 'https://qa-lpg-dnc-back-apigateway-k8.luegopago.com/Payments/api/v2.0',
    soatApiKey: '578b42b7cacd1ab89d732f0c1dee281e',
    soatApiIV: '4e6669be389376dc',
    cloudflareIp: 'https://www.cloudflare.com/cdn-cgi/trace',
  },
}
