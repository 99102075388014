/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState, useContext } from 'react'

import {
  Box,
  CircularProgress,
  FormGroup,
  InputLabel,
  Grid,
  IconButton,
  Typography,
  Tooltip,
  TextField,
  List,
  ListItem,
  Divider,
  ListItemText,
  Menu,
  Button,
  MenuItem,
} from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Axios from 'axios'
import MUIDataTable from 'mui-datatables'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'

import useStyles from './viewOrder.styles'

import BackDrop from 'components/BackDrop'
import { PencilIcon } from 'components/icons/personalizedIcons'
import Tabs from 'components/Tabs'
import { ADMIN } from 'constants/rolesConst'
import { API_ORDERS, API_EDIT_ORDER } from 'constants/urls'
import useAuthContext from 'contextApi/AuthContext'
import convertToCurrencyCop from 'utils/convertToCurrencyCop'
import { downloadPdf, downloadZpl } from 'utils/functions'
import { el } from 'date-fns/locale'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      style={{ width: '100%' }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.string.isRequired,
  index: PropTypes.string.isRequired,
}

export default function ViewOrder() {
  const { userLoggedin } = useContext(useAuthContext)
  const [order, setOrder] = useState([])

  const [anchorEl, setAnchorEl] = useState(null)

  const [showinfoClient, setShowInfoClient] = useState({
    email: false,
    numberPhone: false,
  })
  const [tabs, setTabs] = useState([
    { value: 0, name: 'Información del cliente' },
    { value: 1, name: 'Información venta' },
    { value: 2, name: 'Información de facturación' },
    { value: 3, name: 'Información de envío' },
    { value: 4, name: 'Productos' },
  ])
  const [value, setValue] = useState(0)

  const [errorInfo, setErrorInfo] = useState({
    email: false,
    numberPhone: false,
  })
  const [messageError, setMessageError] = useState('')
  const [infoClient, setInfoClient] = useState({
    email: '',
    numberPhone: '',
  })
  const [isLoading, setIsLoading] = useState(true)
  const { idorder } = useParams()
  const classes = useStyles()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const downloadPrint = async (type, guide) => {
    setIsLoading(true)
    handleClose()
    await Axios.get(`${API_ORDERS}/PintLabel?printType=${type}&guideNumber=${guide}`)
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false)
          const downloadHandlers = {
            Carta: () => downloadPdf(response.data, `Carta - #${guide}`),
            Zebra: () => downloadPdf(response.data, `Zebra - #${guide}`),
            ZPL: () => downloadZpl(response.data, `ZPL - #${guide}`),
          }

          downloadHandlers[type]?.()
        }
      })
      .catch(() => {
        setIsLoading(false)
      })
  }

  const updateInfo = (info, cancelUpdate = false) => {
    if (info === 1) {
      setShowInfoClient({ numberPhone: false, email: cancelUpdate })
    } else if (info === 2) {
      setShowInfoClient({ email: false, numberPhone: cancelUpdate })
    }
  }

  const saveInfo = async () => {
    setIsLoading(true)
    const saveInfo = {
      id: idorder,
      phone: infoClient.numberPhone,
      email: infoClient.email,
    }
    await Axios.put(`${API_EDIT_ORDER}`, saveInfo)
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false)
          setMessageError('')
          setShowInfoClient({
            email: false,
            numberPhone: false,
          })
        }
      })
      .catch(() => {
        setIsLoading(false)
      })
  }
  const validationInfo = (infoData) => {
    if (infoData === 1) {
      if (infoClient.email === '') {
        setErrorInfo({ ...errorInfo, email: true })
        setMessageError('LLene el campo para continuar')
        return false
      }
      const validateEmail =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
      const isEmail = validateEmail.test(infoClient.email)
      if (!isEmail) {
        setErrorInfo({ ...errorInfo, email: true })
        setMessageError('Escriba un correo valido')
        return false
      }
      setErrorInfo({ ...errorInfo, email: false })
      setMessageError('')
    } else {
      if (infoClient.numberPhone === '') {
        setErrorInfo({ ...errorInfo, numberPhone: true })
        setMessageError('LLene el campo para continuar')
        return false
      }
      const validatePhone = /^[0-9]{6,10}$/i
      const isPhone = validatePhone.test(infoClient.numberPhone)
      if (!isPhone) {
        setErrorInfo({ ...errorInfo, numberPhone: true })
        setMessageError('Escriba un número valido')
        return false
      }
      setErrorInfo({ ...errorInfo, numberPhone: false })
      setMessageError('')
    }
    saveInfo()
  }

  useEffect(() => {
    if (userLoggedin.role === ADMIN) {
      setTabs([...tabs, { value: 5, name: 'Observaciones' }])
    }
    const getOrder = async () => {
      Axios.get(`${API_ORDERS}/${idorder}`)
        .then(function (result) {
          setInfoClient({
            email: result.data.billing_email,
            numberPhone: result.data.billing_phone,
          })
          setOrder(result.data)
          setIsLoading(false)
        })
        .catch(function (error) {
          setIsLoading(false)
        })
    }
    getOrder()
  }, [idorder, order.orderNumber])

  const columns = [
    {
      name: 'id',
      options: {
        display: false,
        download: false,
      },
    },
    {
      name: 'hasReturn',
      label: 'DEVOLUCIÓN',
      options: {
        display: 'none',
      },
    },
    {
      name: 'name',
      label: 'NOMBRE PRODUCTO',
      options: {
        customBodyRender: (name, tableMeta) => {
          return (
            <div>
              {name}
              {tableMeta.rowData[1] && <div className={classes.afterReturn} />}
            </div>
          )
        },
      },
    },
    {
      name: 'SKU',
      label: 'SKU',
    },
    {
      name: 'initedPrice',
      label: 'PRECIO',
      options: {
        display: value !== 3 || false,
        download: value !== 3 || false,
      },
    },
    {
      name: 'value',
      label: 'PRECIO PROMOCIÓN',
      options: {
        display: value !== 3 || false,
        download: value !== 3 || false,
      },
    },
    {
      name: 'quantity',
      label: 'CANTIDAD',
    },
    {
      name: 'status',
      label: 'ESTADO',
      options: {
        display: value !== 3 || false,
        download: value !== 3 || false,
      },
    },
    {
      name: 'transportCompany',
      label: 'TRANSPORTADORA',
      options: {
        display: value !== 4 || false,
        download: value !== 4 || false,
      },
    },
    {
      name: 'guideNumber',
      label: 'NÚMERO DE GUÍA',
      options: {
        display: value !== 4 || false,
        download: value !== 4 || false,
      },
    },
    {
      name: 'checkTransport',
      options: {
        display: false,
        download: false,
      },
    },
    {
      name: '',
      label: '',
      options: {
        customBodyRender: (_, tableMeta) => {
          return (
            <div>
              {tableMeta.rowData[9] && tableMeta.rowData[10] && (
                <>
                  <Button aria-controls="downloadGuide" aria-haspopup="true" onClick={handleClick}>
                    <MoreVertIcon />
                  </Button>
                  <Menu
                    id="downloadGuide"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={() => downloadPrint('Carta', tableMeta.rowData[9])}>
                      Descargar Carta
                    </MenuItem>
                    <MenuItem onClick={() => downloadPrint('Zebra', tableMeta.rowData[9])}>
                      Descargar Zebra
                    </MenuItem>
                    <MenuItem onClick={() => downloadPrint('ZPL', tableMeta.rowData[9])}>
                      Descargar ZPL
                    </MenuItem>
                  </Menu>
                </>
              )}
            </div>
          )
        },
      },
    },
  ]

  const options = {
    responsive: 'scroll',
    search: false,
    filterType: 'dropdown',
    selectableRows: false,
    rowHover: true,
    filter: false,
    sort: false,
    print: false,
    serverSide: true,
    fixedSelectColumn: false,
    download: false,
    viewColumns: false,
    textLabels: {
      body: {
        noMatch: `${isLoading ? 'Cargando ...' : 'No hay registros'}`,
      },
      pagination: {
        next: 'Página siguiente',
        previous: 'Página anterior',
        rowsPerPage: 'Filas por página:',
        displayRows: 'de',
      },
      toolbar: {
        search: 'Buscar',
        downloadCsv: 'Descargar CSV',
      },
      selectedRows: {
        text: 'Fila(s) Seleccionada(s)',
      },
    },
  }

  const changeTabs = (tabValue) => {
    setValue(tabValue)
  }

  return (
    <>
      <BackDrop open={isLoading} />
      <Typography variant="h6">
        Información de pedido:
        <span className={classes.numPedido}>{` Nº ${idorder}`}</span>
        {isLoading && (
          <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }} />
        )}
      </Typography>
      {order && (
        <Grid container justify="center" className={classes.divContent}>
          <Tabs value={value} tabs={tabs} onChange={changeTabs} />
          <TabPanel value={value} index={0}>
            <Grid className={classes.tabContent}>
              <FormGroup className={classes.formGroup}>
                <InputLabel htmlFor="datePaid">Fecha y hora</InputLabel>
                <InputLabel htmlFor="datePaid" className={classes.input}>
                  {order.date_created}
                </InputLabel>
              </FormGroup>

              <FormGroup className={classes.formGroup}>
                <InputLabel htmlFor="datePaid">Cliente</InputLabel>
                <InputLabel htmlFor="datePaid" className={classes.input}>
                  {`${order.billing_first_name} ${order.billing_last_name}`}
                </InputLabel>
              </FormGroup>

              <FormGroup className={classes.formGroup}>
                <InputLabel htmlFor="datePaid">Cédula</InputLabel>
                <InputLabel htmlFor="datePaid" className={classes.input}>
                  {order.billing_identification}
                </InputLabel>
              </FormGroup>

              <FormGroup className={classes.formGroup}>
                <InputLabel htmlFor="datePaid" error={!!errorInfo.numberPhone}>
                  Número de teléfono
                </InputLabel>
                {userLoggedin.role === ADMIN && showinfoClient.numberPhone && (
                  <FormGroup className={classes.formInput}>
                    <div className={classes.formField}>
                      <TextField
                        error={!!errorInfo.numberPhone}
                        fullWidth
                        id="numberPhone"
                        name="numberPhone"
                        helperText={<p className={classes.messageError}>{messageError}</p>}
                        value={infoClient.numberPhone || ''}
                        variant="outlined"
                        onChange={(event) => {
                          setInfoClient({ ...infoClient, numberPhone: event.target.value })
                        }}
                      />
                    </div>
                    {!isLoading ? (
                      <>
                        <Tooltip title="Guardar">
                          <IconButton
                            className={classes.btnEdit}
                            onClick={(event) => {
                              event.preventDefault()
                              validationInfo(2)
                            }}
                          >
                            <CheckIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Cancelar">
                          <IconButton
                            className={classes.btnDelete}
                            onClick={(event) => {
                              event.preventDefault()
                              setInfoClient({ ...infoClient, numberPhone: order.billing_phone })
                              setErrorInfo({ numberPhone: false, email: false })
                              setMessageError('')
                              updateInfo(2, false)
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Tooltip>
                      </>
                    ) : (
                      <CircularProgress
                        size={24}
                        style={{ marginLeft: 15, position: 'relative', top: 4 }}
                      />
                    )}
                  </FormGroup>
                )}
                {!showinfoClient.numberPhone && (
                  <InputLabel htmlFor="datePaid" className={classes.input}>
                    {infoClient.numberPhone}
                    {userLoggedin.role === ADMIN && (
                      <Tooltip title="Editar">
                        <IconButton
                          className={classes.btnEdit}
                          onClick={(event) => {
                            event.preventDefault()
                            updateInfo(2, true)
                          }}
                        >
                          <PencilIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </InputLabel>
                )}
              </FormGroup>

              <FormGroup className={classes.formGroup}>
                <InputLabel
                  htmlFor="datePaid"
                  className={showinfoClient.email ? classes.marginEmail : null}
                  error={!!errorInfo.email}
                >
                  Correo electrónico
                </InputLabel>
                {userLoggedin.role === ADMIN && showinfoClient.email && (
                  <FormGroup className={classes.formInput}>
                    <div className={classes.formField}>
                      <TextField
                        error={!!errorInfo.email}
                        fullWidth
                        id="email"
                        name="email"
                        helperText={<p className={classes.messageError}>{messageError}</p>}
                        value={infoClient.email || ''}
                        variant="outlined"
                        onChange={(event) => {
                          setInfoClient({ ...infoClient, email: event.target.value })
                        }}
                      />
                    </div>
                    {!isLoading ? (
                      <>
                        <Tooltip title="Guardar">
                          <IconButton
                            className={classes.btnEdit}
                            onClick={(event) => {
                              event.preventDefault()
                              validationInfo(1)
                            }}
                          >
                            <CheckIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Cancelar">
                          <IconButton
                            className={classes.btnDelete}
                            onClick={(event) => {
                              event.preventDefault()
                              setInfoClient({ ...infoClient, email: order.billing_email })
                              setErrorInfo({ numberPhone: false, email: false })
                              setMessageError('')
                              updateInfo(1, false)
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Tooltip>
                      </>
                    ) : (
                      <CircularProgress
                        size={24}
                        style={{ marginLeft: 15, position: 'relative', top: 4 }}
                      />
                    )}
                  </FormGroup>
                )}
                {!showinfoClient.email && (
                  <InputLabel htmlFor="datePaid" className={classes.input}>
                    {infoClient.email}
                    {userLoggedin.role === ADMIN && (
                      <Tooltip title="Editar">
                        <IconButton
                          className={classes.btnEdit}
                          onClick={(event) => {
                            event.preventDefault()
                            updateInfo(1, true)
                          }}
                        >
                          <PencilIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </InputLabel>
                )}
              </FormGroup>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Grid className={classes.tabContent}>
              <FormGroup className={classes.formGroup}>
                <InputLabel htmlFor="datePaid">Medio de pago</InputLabel>
                <InputLabel htmlFor="datePaid" className={classes.input}>
                  {order.payment_method_title}
                </InputLabel>
              </FormGroup>

              <FormGroup className={classes.formGroup}>
                <InputLabel htmlFor="datePaid">Total con envío</InputLabel>
                <InputLabel htmlFor="datePaid" className={classes.input}>
                  {convertToCurrencyCop(Number(order.subtotal) + Number(order.shipping_cost))}
                </InputLabel>
              </FormGroup>

              <FormGroup className={classes.formGroup}>
                <InputLabel htmlFor="datePaid">Total con IVA</InputLabel>
                <InputLabel htmlFor="datePaid" className={classes.input}>
                  {convertToCurrencyCop(Number(order.subtotal) + Number(order.tax))}
                </InputLabel>
              </FormGroup>

              <FormGroup className={classes.formGroup}>
                <InputLabel htmlFor="datePaid">Costo de envío</InputLabel>
                <InputLabel htmlFor="datePaid" className={classes.input}>
                  {convertToCurrencyCop(Number(order.shipping_cost))}
                </InputLabel>
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <InputLabel htmlFor="datePaid">Comprobante de envío</InputLabel>
                <InputLabel htmlFor="datePaid" className={classes.input}>
                  {order.shipping_tracking}
                </InputLabel>
              </FormGroup>

              <FormGroup className={classes.formGroup}>
                <InputLabel htmlFor="datePaid">IVA</InputLabel>
                <InputLabel htmlFor="datePaid" className={classes.input}>
                  {convertToCurrencyCop(Number(order.tax))}
                </InputLabel>
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <InputLabel htmlFor="datePaid">Valor asumido</InputLabel>
                <InputLabel htmlFor="datePaid" className={classes.input}>
                  {convertToCurrencyCop(Number(order.asumed || '0'))}
                </InputLabel>
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <InputLabel htmlFor="datePaid">Subtotal</InputLabel>
                <InputLabel htmlFor="datePaid" className={classes.input}>
                  {convertToCurrencyCop(Number(order.subtotal || '0'))}
                </InputLabel>
              </FormGroup>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Grid className={classes.tabContent}>
              <FormGroup className={classes.formGroup}>
                <InputLabel htmlFor="datePaid">Cliente</InputLabel>
                <InputLabel htmlFor="datePaid" className={classes.input}>
                  {`${order.billing_first_name} ${order.billing_last_name}`}
                </InputLabel>
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <InputLabel htmlFor="datePaid">Dirección 1</InputLabel>
                <InputLabel htmlFor="datePaid" className={classes.input}>
                  {order.billing_address_1}
                </InputLabel>
              </FormGroup>

              <FormGroup className={classes.formGroup}>
                <InputLabel htmlFor="datePaid">Dirección 2</InputLabel>
                <InputLabel htmlFor="datePaid" className={classes.input}>
                  {order.billing_address_2}
                </InputLabel>
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <InputLabel htmlFor="datePaid">Ciudad</InputLabel>
                <InputLabel htmlFor="datePaid" className={classes.input}>
                  {order.billing_city}
                </InputLabel>
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <InputLabel htmlFor="datePaid">Departamento</InputLabel>
                <InputLabel htmlFor="datePaid" className={classes.input}>
                  {order.billing_state}
                </InputLabel>
              </FormGroup>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Grid className={classes.tabContent}>
              <FormGroup className={classes.formGroup}>
                <InputLabel htmlFor="datePaid">Cliente</InputLabel>
                <InputLabel htmlFor="datePaid" className={classes.input}>
                  {`${order.shipping_first_name} ${order.shipping_last_name}`}
                </InputLabel>
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <InputLabel htmlFor="datePaid">Dirección 1</InputLabel>
                <InputLabel htmlFor="datePaid" className={classes.input}>
                  {order.shipping_address_1}
                </InputLabel>
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <InputLabel htmlFor="datePaid">Dirección 2</InputLabel>
                <InputLabel htmlFor="datePaid" className={classes.input}>
                  {order.shipping_address_2}
                </InputLabel>
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <InputLabel htmlFor="datePaid">Ciudad</InputLabel>
                <InputLabel htmlFor="datePaid" className={classes.input}>
                  {order.shipping_city}
                </InputLabel>
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <InputLabel htmlFor="datePaid">Departamento</InputLabel>
                <InputLabel htmlFor="datePaid" className={classes.input}>
                  {order.shipping_state}
                </InputLabel>
              </FormGroup>
            </Grid>
            <FormGroup className={classes.formGroup}>
              <InputLabel htmlFor="datePaid">Productos enviados</InputLabel>
              {order?.products !== undefined && (
                <MUIDataTable
                  data={order?.products.map((item) => {
                    return [
                      item.id,
                      item.hasReturn,
                      item.name,
                      item.SKU,
                      item.initedPrice,
                      item.value,
                      item.quantity,
                      item.status,
                      item.transportCompany,
                      item.guideNumber,
                      item.checkTransport,
                    ]
                  })}
                  columns={columns}
                  options={options}
                />
              )}
            </FormGroup>
          </TabPanel>
          <TabPanel value={value} index={4}>
            {order && order.products && (
              <MUIDataTable
                data={order.products.map((item) => {
                  return [
                    item.id,
                    item.hasReturn,
                    item.name,
                    item.SKU,
                    item.InitedPrice,
                    item.value,
                    item.quantity,
                    item.status,
                  ]
                })}
                columns={columns}
                options={options}
              />
            )}
          </TabPanel>

          {/* observaciones */}
          <TabPanel value={value} index={5}>
            {order && order.observations ? (
              <List component="nav" aria-label="observations">
                {order.observations.map((observation) => {
                  return (
                    <>
                      <ListItem>
                        <ListItemText primary={observation} />
                      </ListItem>
                      <Divider />
                    </>
                  )
                })}
              </List>
            ) : (
              <Grid container justifyContent="center">
                <Typography> No hay observaciones </Typography>
              </Grid>
            )}
          </TabPanel>
        </Grid>
      )}
    </>
  )
}
