/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Select,
  FormGroup,
  Typography,
  InputLabel,
  Button,
  MenuItem,
} from '@material-ui/core'
import Axios from 'axios'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'

import useStyles from './modalProducts.styles'
import { schema } from './schema'

import DialogAlert from 'components/DialogAlert'
import useCustomDialogAlert from 'components/useCustomDialogAlert'
import { API_PRODUCTS, QUOTE_SHIPPING } from 'constants/urls'
import convertToCurrencyCop from 'utils/convertToCurrencyCop'
import { downloadPdf, downloadZpl } from 'utils/functions'
import { justNumbersDecimal } from 'utils/utils'

const ConsolidateForm = ({
  productsToGuide,
  onClose,
  currentOrder,
  setIsLoadingBackDrop,
  warehouseSelected,
  changeProductsAsReadyToSend,
  isConsolidate,
  isFastDelivery,
}) => {
  const showDialogAlert = useCustomDialogAlert()
  const classes = useStyles()
  const { register, errors } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  })
  const [isCalculate, setIsCalculate] = useState(false)
  const [editable, setEditable] = useState(false)
  const [multipleProducts, setMultipleProducts] = useState(false)
  const [idsProducts, setIdsProducts] = useState([])
  const [dimensions, setDimensions] = useState({
    height: '',
    width: '',
    length: '',
    weight: '',
  })
  const [generalInfo, setGeneralInfo] = useState({
    origin: warehouseSelected,
    assumedValue: 0,
    tariff: 0,
    typePackage: null,
    formatFuide: 'Zebra',
  })
  const [formatGuide, setformatGuide] = useState('Zebra')
  const [dialogAlert, setDialogAlert] = useState({
    open: false,
    title: '',
    message: '',
    actionConfirm: null,
    actionCancel: null,
    textButtonConfirm: '',
    textButtonCancel: '',
    idProduct: 0,
    newOrigin: 0,
  })
  const [activeStep, setActiveStep] = useState(0)
  const maxSteps = productsToGuide.length

  const handleNext = () => {
    const newStep = activeStep + 1
    const tempDimensions = {
      height: productsToGuide[newStep].height,
      width: productsToGuide[newStep].width,
      length: productsToGuide[newStep].length,
      weight: productsToGuide[newStep].weight,
    }
    setDimensions(tempDimensions)
    setActiveStep(newStep)
  }

  const handleBack = () => {
    const newStep = activeStep - 1
    const tempDimensions = {
      height: productsToGuide[newStep].height,
      width: productsToGuide[newStep].width,
      length: productsToGuide[newStep].length,
      weight: productsToGuide[newStep].weight,
    }
    setDimensions(tempDimensions)
    setActiveStep(newStep)
  }

  useEffect(() => {
    const temporalIds = []
    productsToGuide.map((product) => {
      temporalIds.push(product)
    })
    setIdsProducts(temporalIds)
    let sum = 0
    productsToGuide.map((prod) => {
      const totalByQuantity = prod.assumedValue * prod.quantity
      sum += totalByQuantity
    })
    if (!isConsolidate) {
      const tempDimensions = {
        height: productsToGuide[0].height,
        width: productsToGuide[0].width,
        length: productsToGuide[0].length,
        weight: productsToGuide[0].weight,
        assumedValue: parseFloat(sum),
      }
      setDimensions(tempDimensions)
      calculate(tempDimensions)

      if (productsToGuide.length <= 1) {
        setEditable(false)
      } else {
        // multiple Products
        setMultipleProducts(true)
        setEditable(false)
      }
    } else {
      // to consolidate
      setEditable(true)
    }

    setGeneralInfo({
      ...generalInfo,
      assumedValue: sum,
    })
  }, [])

  const handleChange = (event) => {
    if (!isConsolidate) {
      const newProductDimensions = [...idsProducts]
      newProductDimensions[activeStep][event.target.id] = event.target.value
      setIdsProducts(newProductDimensions)
    }
    setDimensions({
      ...dimensions,
      [event.target.id]: event.target.value,
    })
    setIsCalculate(false)
  }

  const changeFormatGuide = (event) => {
    setformatGuide(event.target.value)
  }

  const validateFloatDimentions = (height, width, length, weight) => {
    const expreg = /^[0-9]{1,}$|^[0-9]+([.][0-9]+)?$/
    if (expreg.test(height) && expreg.test(width) && expreg.test(length) && expreg.test(weight)) {
      return true
    }
    return false
  }
  const validateFields = (field) => {
    const existsFields = !field.height || !field.width || !field.length || !field.weight
    const decimalFields = validateFloatDimentions(
      field.height,
      field.width,
      field.length,
      field.weight
    )
    if (existsFields) {
      showDialogAlert(
        true,
        '¡Advertencia!',
        `Llena todos los campos para hacer el calculo`,
        'Cerrar'
      )
      return false
    }
    if (!decimalFields) {
      showDialogAlert(true, '¡Advertencia!', `Los campos no son válido`, 'Cerrar')
      return false
    }
    if (field.height < 0 || field.width < 0 || field.length < 0 || field.weight < 0) {
      showDialogAlert(true, '¡Advertencia!', `No se permiten valores negativos`, 'Cerrar')
      return false
    }
    return true
  }

  const calculate = async (dimensionData) => {
    if (!validateFields(dimensionData)) return false

    const temporalIds = []
    productsToGuide.map((product) => {
      temporalIds.push(product)
    })
    const data = {
      orderId: currentOrder,
      length: dimensionData.length,
      height: dimensionData.height,
      width: dimensionData.width,
      weight: dimensionData.weight,
      fastDelivery: isFastDelivery,
      assumedValue: dimensionData.assumedValue || generalInfo.assumedValue,
      consolidated: isConsolidate,
      wareHouseId: productsToGuide[0].origin,
      products: temporalIds,
    }
    try {
      setIsLoadingBackDrop(true)
      await Axios.post(`${QUOTE_SHIPPING}`, data)
        .then((response) => {
          setIsLoadingBackDrop(false)
          if (response.status === 200) {
            setGeneralInfo({
              ...generalInfo,
              tariff: response.data.totalFreight,
              typePackage: response.data.packageType,
              assumedValue: data.assumedValue,
              carrierCode: response.data.carrierCode,
              carrierService: response.data.carrierService,
            })
            if (!isConsolidate) setEditable(false)
            setIsCalculate(true)
          }
        })
        .catch((e) => {
          setIsLoadingBackDrop(false)
          setIsCalculate(true)
          setEditable(true)
          console.log(e)
        })
    } catch (error) {
      console.log(error)
    }
  }

  const OpenGenerateGuide = (e) => {
    e.preventDefault()
    if (!validateFields(dimensions)) return false
    const data = {
      orderId: currentOrder,
      length: dimensions.length,
      height: dimensions.height,
      width: dimensions.width,
      weight: dimensions.weight,
      assumedValue: generalInfo.assumedValue,
      wareHouseId: generalInfo.origin.id,
      totalFreight: generalInfo.tariff,
      consolidated: isConsolidate,
      products: idsProducts,
      carrierCode: generalInfo.carrierCode,
      carrierService: generalInfo.carrierService,
      printType: formatGuide,
    }

    if (!editable && productsToGuide.length <= 1) {
      setDialogAlert({
        open: true,
        title: '¡Advertencia!',
        message: `¿Desea conservar las dimensiones actuales del paquete?`,
        actionConfirm: () => {
          ValidateOverrun(data)
          setEditable(false)
        },
        actionCancel: () => {
          handleCloseDialog()
          setEditable(true)
        },
        textButtonConfirm: 'Si',
        textButtonCancel: 'No',
      })
    } else {
      ValidateOverrun(data)
    }
  }

  const ValidateOverrun = (data) => {
    if (generalInfo.assumedValue > generalInfo.tariff) {
      setDialogAlert({
        open: true,
        title: '¡Sobrecosto!',
        message: `El envío de esta mercancía presenta un sobrecosto de ${
          generalInfo.tariff - generalInfo.assumedValue
        } ¿Desea continuar?`,
        actionConfirm: () => {
          handleCloseDialog()
          generateGuide(data)
        },
        textButtonConfirm: 'Aceptar',
        textButtonCancel: 'Cancelar',
      })
    } else {
      generateGuide(data)
    }
  }

  const generateGuide = async (data) => {
    setIsLoadingBackDrop(true)

    try {
      await Axios.post(`${API_PRODUCTS}/TransportLuegoPaGoDataCapture`, data)
        .then((response) => {
          setIsLoadingBackDrop(false)
          if (response.status === 200) {
            const handleDownload = (info, downloadFn) => {
              const fileName = `${info.transportationCompany} - #${info.guideNumber}`
              downloadFn(info.print, fileName)
            }
            const downloadHandlers = {
              Carta: () => handleDownload(response.data, downloadPdf),
              Zebra: () => handleDownload(response.data, downloadPdf),
              ZPL: () => handleDownload(response.data, downloadZpl),
            }

            downloadHandlers[response.data.printType]?.()

            setDialogAlert({
              open: true,
              title: 'Su guía fue generada exitosamente',
              message: `Empresa transportadora: ${response.data.transportationCompany} Numero de guía: ${response.data.guideNumber}`,
              textButtonConfirm: 'Guardar y continuar',
              textButtonCancel: null,
              actionConfirm: () => {
                changeProductsAsReadyToSend(productsToGuide)
                handleCloseDialog()
                onClose()
              },
            })
          }
        })
        .catch((e) => {
          setIsLoadingBackDrop(false)
          console.log(e)
        })
    } catch (error) {
      console.log(error)
    }
  }

  const handleCloseDialog = () => {
    setDialogAlert({ ...dialogAlert, open: false })
  }
  return (
    <>
      <DialogAlert
        open={dialogAlert.open}
        title={dialogAlert.title}
        handleClose={handleCloseDialog}
        message={dialogAlert.message}
        textButtonConfirm={dialogAlert.textButtonConfirm}
        actionConfirm={dialogAlert.actionConfirm}
        actionCancel={dialogAlert.actionCancel}
        textButtonCancel={dialogAlert.textButtonCancel}
      />

      <Dialog maxWidth="md" onClose={onClose} open disableBackdropClick disableEscapeKeyDown>
        <DialogTitle className={classes.title} disableTypography>
          Información de Pedido: N°
          {currentOrder}
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            justify="space-between"
            alignItems="baseline"
            className={classes.containerDataConsolidate}
            spacing={3}
          >
            <Grid item md={12}>
              <Typography variant="h6">
                {productsToGuide.length > 1 && isConsolidate
                  ? `${productsToGuide.length}  Productos Consolidados`
                  : multipleProducts
                  ? `Producto ${productsToGuide[activeStep].name} SKU #${
                      productsToGuide[activeStep].sku
                    } ${activeStep + 1}  de ${maxSteps} `
                  : `Producto ${productsToGuide[0].name} SKU #${productsToGuide[0].sku}`}
              </Typography>
            </Grid>

            <Grid item md={6}>
              <Grid container justify="space-between" alignItems="flex-start">
                <FormGroup className={classes.formInputInfo}>
                  <InputLabel
                    htmlFor="height"
                    error={!!errors.height}
                    className={classes.labelSize}
                  >
                    Altura(cm)
                  </InputLabel>

                  <TextField
                    id="height"
                    name="height"
                    onChange={(event) => handleChange(event)}
                    inputRef={register}
                    error={!!errors.height}
                    variant="outlined"
                    fullWidth
                    disabled={!editable}
                    inputProps={{ min: 0 }}
                    value={dimensions.height || ''}
                    helperText={errors?.height?.message}
                    onKeyPress={justNumbersDecimal}
                  />
                </FormGroup>
                <FormGroup className={classes.formInputInfo}>
                  <InputLabel error={!!errors.width} htmlFor="width" className={classes.labelSize}>
                    Ancho(cm)
                  </InputLabel>
                  <TextField
                    id="width"
                    name="width"
                    onChange={(event) => {
                      handleChange(event)
                    }}
                    variant="outlined"
                    disabled={!editable}
                    fullWidth
                    inputRef={register}
                    error={!!errors.width}
                    helperText={errors?.width?.message}
                    onKeyPress={justNumbersDecimal}
                    inputProps={{ min: 0 }}
                    value={dimensions.width}
                  />
                </FormGroup>
              </Grid>

              <FormGroup className={classes.formInputDetails}>
                <InputLabel htmlFor="assumedValue" className={classes.labelSize}>
                  Valor asumido
                </InputLabel>
                <TextField
                  id="assumedValue"
                  name="assumedValue"
                  fullWidth
                  error={Boolean(errors.assumedValue)}
                  onChange={(event) => handleChange(event)}
                  type="text"
                  variant="outlined"
                  disabled
                  value={convertToCurrencyCop(generalInfo.assumedValue)}
                  inputProps={{ min: 0 }}
                />
              </FormGroup>

              <FormGroup className={classes.formInputDetails}>
                <InputLabel htmlFor="packageType" className={classes.labelSize}>
                  Tipo de empaque
                </InputLabel>

                <TextField
                  id="packageType"
                  name="packageType"
                  variant="outlined"
                  disabled
                  fullWidth
                  value={generalInfo.typePackage}
                  helperText="Tipo de empaque."
                />
              </FormGroup>
            </Grid>

            <Grid item md={6} className={classes.labelSize}>
              <Grid container justify="space-between" alignItems="flex-start">
                <FormGroup className={classes.formInputInfo}>
                  <InputLabel
                    error={!!errors.length}
                    htmlFor="length"
                    className={classes.labelSize}
                  >
                    Largo(cm)
                  </InputLabel>
                  <TextField
                    id="length"
                    name="length"
                    onChange={(event) => handleChange(event)}
                    variant="outlined"
                    fullWidth
                    disabled={!editable}
                    inputRef={register}
                    error={!!errors.length}
                    helperText={errors?.length?.message}
                    F={justNumbersDecimal}
                    value={dimensions.length}
                    inputProps={{ min: 0 }}
                  />
                </FormGroup>
                <FormGroup className={classes.formInputInfo}>
                  <InputLabel
                    error={!!errors.weight}
                    htmlFor="weight"
                    className={classes.labelSize}
                  >
                    Peso(kg)
                  </InputLabel>
                  <TextField
                    id="weight"
                    name="weight"
                    onChange={(event) => handleChange(event)}
                    variant="outlined"
                    disabled={!editable}
                    fullWidth
                    inputRef={register}
                    error={!!errors.weight}
                    helperText={errors?.weight?.message}
                    onKeyPress={justNumbersDecimal}
                    value={dimensions.weight}
                  />
                </FormGroup>
              </Grid>
              <FormGroup className={classes.formInputDetails}>
                <InputLabel htmlFor="cost" className={classes.labelSize}>
                  Costo del envío
                </InputLabel>
                <TextField
                  id="cost"
                  name="cost"
                  value={convertToCurrencyCop(generalInfo.tariff)}
                  fullWidth
                  variant="outlined"
                  disabled
                  type="text"
                />
              </FormGroup>
              <FormGroup className={classes.formInputDetails}>
                <InputLabel htmlFor="GuideFormat" className={classes.labelSize}>
                  Formato Guía
                </InputLabel>

                <Select
                  labelId="GuideFormat"
                  id="GuideFormat"
                  value={formatGuide}
                  variant="outlined"
                  onChange={(event) => changeFormatGuide(event)}
                >
                  <MenuItem value="Zebra">Zebra</MenuItem>
                  <MenuItem value="Carta">Carta</MenuItem>
                  <MenuItem value="ZPL">ZPL</MenuItem>
                </Select>
              </FormGroup>

              {editable ? (
                <Button
                  style={{ marginTop: '5%' }}
                  onClick={() => calculate(dimensions)}
                  variant="contained"
                  type="submit"
                  color="secondary"
                  className={classes.buttonshoverGreen}
                >
                  CALCULAR
                </Button>
              ) : null}
            </Grid>
          </Grid>
          <Grid container justify="center" className={classes.buttons}>
            {multipleProducts ? (
              <>
                <Grid className={classes.alingCenter} item md={12}>
                  <Typography variant="button" gutterBottom>
                    {`producto ${activeStep + 1} de ${maxSteps} `}
                  </Typography>
                </Grid>
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  variant="contained"
                  color="secondary"
                  className={classes.buttonshoverGreen}
                >
                  ANTERIOR
                </Button>
                <Button
                  disabled={activeStep === productsToGuide.length - 1}
                  onClick={handleNext}
                  variant="contained"
                  color="secondary"
                  className={classes.buttonshoverGreen}
                >
                  SIGUIENTE
                </Button>
              </>
            ) : null}
            <Button
              onClick={() => onClose()}
              variant="contained"
              color="secondary"
              className={classes.buttonHoverRed}
            >
              Cancelar
            </Button>
            <Button
              disabled={
                !isCalculate ||
                (!isConsolidate && editable) ||
                (!isConsolidate && activeStep !== productsToGuide.length - 1)
              }
              onClick={OpenGenerateGuide}
              variant="contained"
              color="secondary"
              className={classes.buttonshoverGreen}
            >
              Generar Guía
            </Button>
          </Grid>
          {/* </form> */}
        </DialogContent>
      </Dialog>
    </>
  )
}

ConsolidateForm.propTypes = {
  productsToGuide: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])).isRequired,
  orders: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])).isRequired,
  onClose: PropTypes.func.isRequired,
}

export default ConsolidateForm
