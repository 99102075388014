import { createCipheriv, createDecipheriv } from 'crypto'

import { routeBase } from 'constants/environments.constants'

const algorithm = 'aes-256-cbc'

const secretKeyHex = routeBase[process.env.REACT_APP_ENVIRONMENT].productsKey
const ivHex = routeBase[process.env.REACT_APP_ENVIRONMENT].productsIv

export function encryptAES256CBC(text) {
  const secretKey = Buffer.from(secretKeyHex, 'hex')
  const iv = Buffer.from(ivHex, 'hex')

  if (secretKey.length !== 32 || iv.length !== 16) {
    throw new Error('La clave secreta debe ser de 32 bytes y el IV de 16 bytes.')
  }

  const cipher = createCipheriv(algorithm, secretKey, iv)
  let encrypted = cipher.update(text, 'utf-8', 'base64')
  encrypted += cipher.final('base64')

  return encrypted
}

export function decryptAES256CBC(encryptedText) {
  const secretKey = Buffer.from(secretKeyHex, 'hex')
  const iv = Buffer.from(ivHex, 'hex')

  if (secretKey.length !== 32 || iv.length !== 16) {
    throw new Error('La clave secreta debe ser de 32 bytes y el IV de 16 bytes.')
  }

  const decipher = createDecipheriv(algorithm, secretKey, iv)
  let decrypted = decipher.update(encryptedText, 'base64', 'utf-8')
  decrypted += decipher.final('utf-8')

  return decrypted
}
