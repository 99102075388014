import React, { useEffect, useState, useContext } from 'react'

import DateFnsUtils from '@date-io/date-fns'
import {
  FormGroup,
  InputLabel,
  InputAdornment,
  Typography,
  TextField,
  Button,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { es } from 'date-fns/locale'
import moment from 'moment'

import useStyles from './saleReport.styles'

import BackDrop from 'components/BackDrop'
import { CalendarIcon } from 'components/icons/personalizedIcons'
import useCustomDialogAlert from 'components/useCustomDialogAlert'
import { SUPER_ADMIN } from 'constants/rolesConst'
import useAuthContext from 'contextApi/AuthContext'
import { exportOrders } from 'services/orders/orders.services'
import { getSellersServices } from 'services/sellers/sellers.services'

export default function SaleReport() {
  const showDialogAlert = useCustomDialogAlert()
  const { userLoggedin } = useContext(useAuthContext)
  const classes = useStyles()
  const [sellers, setSellers] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState({ startDate: false, endDate: false, sellerId: false })
  const [report, setReport] = useState({
    startDate: '',
    endDate: '',
  })
  const getSellers = () => {
    setIsLoading(true)
    getSellersServices()
      .then((response) => {
        setIsLoading(false)
        setSellers(response.data.data)
      })
      .catch(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (userLoggedin.role === SUPER_ADMIN) {
      getSellers()
    }
  }, [])
  const getAccountingReport = () => {
    const { startDate, endDate, idSeller } = report
    if (userLoggedin.role === SUPER_ADMIN && !idSeller) {
      showDialogAlert(true, '¡Advertencia!', `Ingrese el seller para continuar`, 'Cerrar')
      return false
    }
    if (startDate === '' || endDate === '') {
      showDialogAlert(
        true,
        '¡Advertencia!',
        `Ingrese las fechas para descargar el reporte`,
        'Cerrar'
      )
      return false
    }
    if (moment(endDate) < moment(startDate)) {
      showDialogAlert(
        true,
        '¡Advertencia!',
        `La fecha inicial debe ser menor a la fecha final`,
        'Cerrar'
      )
      return false
    }
    const diffEnDias = moment(endDate).diff(moment(startDate), 'days')
    if (diffEnDias > 60) {
      showDialogAlert(
        true,
        '¡Advertencia!',
        `La fecha no deben de tener mas de 60 dias de diferencia`,
        'Cerrar'
      )
      return false
    }
    exportOrders({ startDate, endDate, idSeller })
      .then((response) => {
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(new Blob([response.data], { type: 'text/xlsx' }))
        link.setAttribute('download', `ordenesByseller_${new Date().toLocaleDateString()}.xlsx`)
        document.body.appendChild(link)
        link.click()
      })
      .catch(() => {
        setIsLoading(false)
      })
  }
  return (
    <div>
      <BackDrop open={isLoading} />
      <Typography variant="h6">Reporte de ventas</Typography>
      <div className={classes.content}>
        {userLoggedin.role === SUPER_ADMIN && (
          <FormGroup className={`${classes.component} ${classes.sellersComponent}`}>
            <InputLabel className={classes.labelSize}>selllers</InputLabel>
            <Autocomplete
              id="seachSeller"
              options={sellers}
              getOptionLabel={(option) => option.name || option.email}
              style={{ width: 'auto' }}
              autoComplete
              includeInputInList
              renderInput={(params) => (
                <>
                  <TextField {...params} variant="outlined" fullWidth />
                </>
              )}
              onChange={(event, NewValue) => {
                setReport({ ...report, idSeller: NewValue.sellerId })
              }}
            />
          </FormGroup>
        )}
        <FormGroup className={classes.component}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
            <InputLabel className={classes.labelSize}>Fecha inicial</InputLabel>
            <DatePicker
              id="startDate"
              value={report.startDate || null}
              onChange={(value) => {
                const date = new Date(value)
                const month =
                  date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
                const dateInitial = moment(
                  `${date.getFullYear()}-${month}-${
                    date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`
                  }`
                )
                  .format()
                  .replace('-05:00', '')
                setReport({
                  ...report,
                  startDate: dateInitial,
                })
              }}
              format="yyyy-MM-dd"
              inputVariant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarIcon />
                  </InputAdornment>
                ),
              }}
              disablePast={false}
              KeyboardButtonProps={{ style: { padding: 0 } }}
            />
          </MuiPickersUtilsProvider>
        </FormGroup>
        <FormGroup className={classes.component}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
            <InputLabel className={classes.labelSize}>Fecha final</InputLabel>
            <DatePicker
              id="endDate"
              value={report.endDate || null}
              onChange={(value) => {
                const date = new Date(value)
                const month =
                  date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
                const dateEnd = moment(
                  `${date.getFullYear()}-${month}-${
                    date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`
                  }`
                )
                  .format()
                  .replace('-05:00', '')
                setReport({
                  ...report,
                  endDate: dateEnd,
                })
              }}
              format="yyyy-MM-dd"
              inputVariant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarIcon />
                  </InputAdornment>
                ),
              }}
              disablePast={false}
              KeyboardButtonProps={{ style: { padding: 0 } }}
            />
          </MuiPickersUtilsProvider>
        </FormGroup>
      </div>
      <Button
        className={classes.btnBackgroundPrimary}
        variant="contained"
        color="secondary"
        onClick={getAccountingReport}
      >
        Descargar Reporte
      </Button>
    </div>
  )
}
