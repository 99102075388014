/* eslint-disable react/prop-types */
import React, { useState, useContext, useEffect } from 'react'

import {
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import Modal from '@material-ui/core/Modal'
import { makeStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import * as Sentry from '@sentry/react'
import Axios from 'axios'
import jwtDecode from 'jwt-decode'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import useCustomDialogAlert from 'components/useCustomDialogAlert'
import { CLAIM_ROLE } from 'constants/claimsConstants'
import { ADMIN, DROPSHIPPER, SELLER, SUPER_ADMIN } from 'constants/rolesConst'
import { routesLink } from 'constants/routesConsts'
import { USER_VERIFY_TWO_FACTOR_TOKEN } from 'constants/urls'
import useAuthContext from 'contextApi/AuthContext'
import { findRole } from 'utils/functions'
import { onKeyPressJustNumbers } from 'utils/utils'

function getModalStyle() {
  const top = 40
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 530,
    height: 398,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 4, 3, 4),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  form: {
    height: 160,
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    marginTop: 10,
  },
  spanError: {
    color: 'red',
  },
  spanEmail: {
    color: 'black',
    fontWeight: 500,
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  btnCancel: {
    width: 152,
    marginRight: 10,
    backgroundColor: theme.palette.grey.main,

    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  btnBackgroundPrimary: {
    minWidth: '100px',
    padding: '8px, 20px, 10px, 20px',
    borderRadius: '50px',
    border: 'none',
    margin: '20px 0',
    backgroundColor: '#5e44ff',
    color: 'white',
    fontWeight: '500',
    '&:hover': {
      fontWeight: 'bolder',
      backgroundColor: '#ef0bef',
      boxShadow: '-7px 7px 0px #000000',
      border: '2px solid #000000',
      borderRadius: '50px',
      opacity: '1',
    },
  },
  topInputLabel: {
    marginBottom: 4,
    fontSize: 16,
  },
  text: {
    padding: '0 60px 0 60px',
    textAlign: 'center',
  },
  divButtons: {
    marginTop: 15,
  },
}))

export default function CodeVerification({ open, setOpen, model, email }) {
  const { getAccessToken, setAccessToken, updateCategoryLocal } = useContext(useAuthContext)
  const history = useHistory()
  const [alertMessage, setAlertMessage] = useState('')
  const classes = useStyles()
  const [loading, setLoading] = useState(false)

  const [modalStyle] = useState(getModalStyle)
  const showDialogAlert = useCustomDialogAlert()
  const { register, handleSubmit, errors } = useForm({ mode: 'onChange' })
  const [isLoading, setIsLoading] = useState(false)
  const [emailCodeValidation, setEmailCodeValidation] = useState('')

  useEffect(() => {
    if (email && email.length > 0) {
      const arrayemail = email.split('@')[1]
      setEmailCodeValidation(`${email.substring(0, 4)}....@${arrayemail}`)
    }
  }, [])
  const handleClose = () => {
    setOpen(false)
  }

  const onSubmit = async (data) => {
    setAlertMessage('')
    setLoading(true)

    try {
      const response = await Axios.post(`${USER_VERIFY_TWO_FACTOR_TOKEN}`, {
        userName: model.userName,
        password: model.password,
        code: data.code,
      })

      if (response) {
        setLoading(false)
      }

      if (response.data.token) {
        const decoded = jwtDecode(response.data.token)

        let role = ''

        if (Array.isArray(decoded[CLAIM_ROLE])) {
          role = findRole(decoded[CLAIM_ROLE])
        } else {
          role = decoded[CLAIM_ROLE]
        }

        if (
          role.toLowerCase() !== DROPSHIPPER.toLowerCase() &&
          role.toLowerCase() !== SELLER.toLowerCase() &&
          role.toLowerCase() !== ADMIN.toLowerCase() &&
          role.toLowerCase() !== SUPER_ADMIN.toLowerCase()
        ) {
          setAlertMessage('Usuario o contraseña inválidos.')

          return
        }

        setAccessToken(response.data.token)

        history.push(routesLink.dashboard)
      }
    } catch (error) {
      setLoading(false)

      if (error.response && error.response.data && error.response.data.errors) {
        Sentry.captureException(error.response.data.errors[0])

        if (error.response.data.code === 400) {
          if (
            error.response.data.errors[0] === "Value cannot be null. (Parameter 'user')" ||
            error.response.data.errors[0] === 'Usuario/Contraseña incorrecto.'
          ) {
            error.response.data.errors[0] = 'Usuario o Contraseña Incorrecto.'
          } else if (error.response.data.errors[0] === 'Código Incorrecto.') {
            error.response.data.errors[0] = 'Código Incorrecto.'
          } else {
            error.response.data.errors[0] = 'Hubo un error en la petición, intente de nuevo'
          }
        } else if (error.response.data.code === 500 || error.response.data.code === 503) {
          error.response.data.errors[0] = 'Error en el servidor'
        }
        setAlertMessage(error.response.data.errors[0])
      }
    }
  }

  return (
    <Modal disableBackdropClick disableEscapeKeyDown open={open} onClose={handleClose}>
      <div style={modalStyle} className={classes.paper}>
        <Typography className={classes.title} variant="h5">
          Valida tu código
        </Typography>
        <Typography className={classes.text} variant="body1">
          Te hemos enviado al correo{' '}
          <span className={classes.spanEmail}>{emailCodeValidation}</span> con el código de
          seguridad
        </Typography>
        {alertMessage && (
          <Alert
            severity="error"
            onClose={() => {
              setAlertMessage('')
            }}
          >
            {alertMessage}
          </Alert>
        )}
        <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
          <InputLabel error={errors.code} className={classes.topInputLabel}>
            Código de seguridad
          </InputLabel>
          <TextField
            name="code"
            variant="outlined"
            onKeyPress={onKeyPressJustNumbers}
            error={errors.code}
            helperText={errors?.code?.message}
            inputRef={register({
              required: 'Campo requerido',
              pattern: {
                value: /^([0-9])*$/,
                message: 'sólo se permiten números',
              },
              maxLength: {
                value: 6,
                message: 'máximo 6 números',
              },
              minLength: {
                value: 6,
                message: 'mínimo 6 números',
              },
            })}
          />
          <Grid container justify="center" className={classes.divButtons}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.btnCancel}
              disabled={isLoading}
              onClick={handleClose}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              color="secondary"
              className={classes.btnAceptar}
              className={classes.btnBackgroundPrimary}
              type="submit"
              disabled={loading}
            >
              Validar
              {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </Button>
          </Grid>
        </form>
      </div>
    </Modal>
  )
}
