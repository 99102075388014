/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react'

import {
  Button,
  Grid,
  IconButton,
  Typography,
  Tooltip,
  TextField,
  FormControlLabel,
  Switch,
  FormGroup,
  TextareaAutosize,
  Link,
} from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'
import axios from 'axios'
import MUIDataTable from 'mui-datatables'
import { useHistory, useParams } from 'react-router-dom'

import FiltersProducts from './FiltersProducts'
import useStyles from './products.styles'

import BackDrop from 'components/BackDrop'
import { EyeIcon, DefaultImage, DeleteIcon } from 'components/icons/personalizedIcons'
import ImageTooltip from 'components/ImageTooltip'
import useCustomDialogAlert from 'components/useCustomDialogAlert'
import { PROMOTIONS } from 'constants/promotions'
import { routesLink } from 'constants/routesConsts'
import { MICROSERVICES_PRODUCTS } from 'constants/urls'
import { useProduct } from 'contextApi/ProductContext'
import {
  approve,
  approvePending,
  rejectPendingPending,
  updateMassive,
  deleteMassive,
  deleteById,
  getProducts,
} from 'services/productsSellerAdmin/product.sellerAdmin.service'
import convertToCurrencyCop from 'utils/convertToCurrencyCop'
import getOptionsTables from 'utils/tableMaterial/optionTables'

export default function ProductsByStore() {
  const { setAllRowsSelectedContext, allRowsSelectedContext, setIsModified } = useProduct()
  const classes = useStyles()
  const { idSeller } = useParams()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [typeAction, setTypeAction] = useState(0)
  const [data, setData] = useState([])
  const [pagination, setPagination] = useState({
    page: 0,
    perPage: 10,
    prevPage: '',
    nextPage: '',
  })

  const [total, setTotal] = useState(0)
  const [filters, setFilters] = useState({})
  const [filtered, setFiltered] = useState({
    typeFilter: 2,
    totalFilter: '',
  })
  const typeProducts = ['simple', 'virtual', 'service']
  const showDialogAlert = useCustomDialogAlert()
  const pendingApprovals = async (perPage = 10, page = 1, sellerId = idSeller) => {
    setPagination({
      ...pagination,
      page: 0,
      perPage: 10,
    })
    setData([])
    setAllRowsSelectedContext([])
    setIsLoading(() => true)
    let url = ''
    url = `${MICROSERVICES_PRODUCTS}/pendingApprovals/?page=${page}&pagesize=${perPage}&SellerId=${sellerId}`
    try {
      const response = await axios.get(url)
      setIsLoading(() => false)
      if (response.status === 200) {
        if (response.data?.data && response.data?.data.length > 0) {
          response.data.data.sort((a, b) => {
            return (
              new Date(b.dateModified || b.pendingChanges?.dateCreated).getTime() -
              new Date(a.dateModified || a.pendingChanges?.dateCreated).getTime()
            )
          })
          response.data.data.sort(
            (a, b) =>
              new Date(b.dateModified || b.pendingChanges?.dateModified).getTime() -
              new Date(a.dateModified || a.pendingChanges?.dateModified).getTime()
          )
          setData(response.data?.data || [])
        } else {
          setData([])
        }
        setPagination({
          page: page === 0 ? 0 : page - 1,
          perPage: perPage,
        })
        setTotal(filters ? response?.data?.pagination?.records : filters?.draft)
      }
    } catch (e) {
      setIsLoading(() => false)
      console.log(e)
    }
  }
  const setFiltersApi = async (
    filtersData = filters,
    type = 2,
    perPage = 10,
    page = 1,
    text = '',
    sellerId = idSeller
  ) => {
    setAllRowsSelectedContext([])
    setIsLoading(() => true)
    try {
      const response = await getProducts(type, filtered, sellerId, page, perPage, text)
      setData(response.data?.data || [])
      setIsLoading(() => false)
      if (response.data?.data) {
        setPagination({
          page: page === 0 ? 0 : page - 1,
          perPage: perPage,
        })
        if (type === 2) {
          setTotal(
            response?.data?.pagination ? response?.data?.pagination?.records : filters?.draft
          )
        } else {
          setTotal(
            response?.data?.pagination ? response?.data?.pagination?.records : filtered.totalFilter
          )
        }
      }
    } catch (e) {
      setIsLoading(() => false)
      console.log(e)
    }
  }
  const approveProducts = async (productList, type) => {
    setIsLoading(() => true)
    try {
      const response = await approve(productList, type, idSeller)
      showDialogAlert(true, response.message, null, 'Cerrar', '', true)
      setFiltersApi(filters, filtered.typeFilter, pagination.perPage, pagination.page + 1)
    } catch (error) {
      setIsLoading(() => false)
    }
    return true
  }
  const approveProductsPending = async (productProperties) => {
    setIsLoading(() => true)
    try {
      const response = await approvePending(productProperties)
      if (response.result.status === 200) {
        showDialogAlert(true, response.message, null, 'Cerrar', '', true)
        pendingApprovals()
      }
    } catch (error) {
      setIsLoading(() => false)
    }
    return true
  }
  const rejectPendingChanges = async (productList, value) => {
    setIsLoading(() => true)
    rejectPendingPending(productList, value)
      .then((response) => {
        setIsLoading(() => false)
        showDialogAlert(true, response.message, null, 'Cerrar', '', true)
        pendingApprovals()
      })
      .catch(() => {
        setIsLoading(() => false)
      })
  }
  const ProductUpdateMassive = async (productProperties) => {
    setIsLoading(() => true)
    updateMassive(productProperties, idSeller)
      .then((response) => {
        setAllRowsSelectedContext([])
        setIsLoading(() => false)
        showDialogAlert(true, '¡Cambio exitoso!', response.message, 'Cerrar', '', true)
      })
      .catch(() => {
        setIsLoading(() => false)
        showDialogAlert(
          true,
          '¡Advertencia!',
          `Ha ocurrido un error al actualizar el producto`,
          'Cerrar'
        )
      })
  }
  const productsToDeleteMassive = async (productList) => {
    setIsLoading(() => true)
    deleteMassive(productList, idSeller)
      .then((response) => {
        setIsLoading(() => false)
        showDialogAlert(true, '¡Cambio exitoso!', response.message, 'Cerrar', '', true)
        setFiltersApi(filters, 2, pagination.perPage, pagination.page)
      })
      .catch(() => {
        setIsLoading(() => false)
        showDialogAlert(
          true,
          '¡Advertencia!',
          `No se eliminaron todo los productos seleccionados`,
          'Cerrar'
        )
      })
  }
  const activateAndeactivateTransportMassive = async (typeSelected, selectedRows, displayData) => {
    const productList = []
    const productProperties = []
    selectedRows.data.forEach((Selected) => {
      const rowSelected = displayData.find((row) => row.dataIndex === Selected.index)
      const dataRow = rowSelected.data[0]
      productList.push(dataRow)
    })

    productList.forEach((id) => {
      const productSelect = data.find((product) => product.id === id)
      if (productSelect) {
        productSelect.checkTransport = typeSelected === 5
        if (productSelect.type === 'variable') {
          productSelect.variations.map((variation) => {
            variation.checkTransport = typeSelected === 5
          })
        }
        productProperties.push(productSelect)
      }
    })
    ProductUpdateMassive(productProperties)
  }
  const handleChangeSelectAccion = async (
    e,
    selectedRows,
    displayData,
    idProduct = null,
    typeSelected = typeAction
  ) => {
    e.preventDefault()

    if (typeSelected === '') {
      return false
    }
    if (typeSelected === 5 || typeSelected === 6) {
      activateAndeactivateTransportMassive(typeSelected, selectedRows, displayData)
      return false
    }
    const productList = []
    const productProperties = []
    if (idProduct) {
      if (filtered.typeFilter === 2) {
        const productSelect = data.find((product) => product.id === idProduct)
        if (productSelect) {
          productSelect.status = 'publish'
          if (productSelect.type === 'variable') {
            productSelect.variations.map((variation) => {
              variation.status = 'publish'
            })
          }
          productList.push(productSelect)
        }
      } else {
        const productApprove = data.find((dataProduct) => dataProduct.id === idProduct)
        if (productApprove) {
          if (typeProducts.includes(productApprove.type) && productApprove.pendingChanges) {
            productProperties.push(productApprove.id)
          } else if (productApprove.type === 'variable') {
            if (productApprove.pendingChanges) {
              productProperties.push(productApprove.id)
            }
            productApprove.variations.forEach((variation) => {
              if (variation.pendingChanges) {
                productProperties.push(variation.id)
              }
            })
          }
        }
      }
    } else if (filtered.typeFilter === 2 || filtered.typeFilter === 11) {
      selectedRows.data.forEach((Selected) => {
        const rowSelected = displayData.find((row) => row.dataIndex === Selected.index)
        const dataRow = rowSelected.data[0]
        productList.push(dataRow)
      })
      if (typeSelected === 2) {
        setIsLoading(() => true)
        productsToDeleteMassive(productList)
        return false
      }
      if (filtered.typeFilter === 11) {
        productList.forEach((id) => {
          const productSelect = data.find((product) => product.id === id)
          if (productSelect) {
            productSelect.catalogVisibility = typeSelected === 3
            if (productSelect.type === 'variable') {
              productSelect.variations.map((variation) => {
                variation.catalogVisibility = typeSelected === 3
              })
            }
            productProperties.push(productSelect)
          }
        })
        ProductUpdateMassive(productProperties)
        return false
      }
      productList.forEach((id) => {
        const productSelect = data.find((product) => product.id === id)
        if (productSelect) {
          productSelect.status = 'publish'
          if (productSelect.type === 'variable') {
            productSelect.variations.map((variation) => {
              variation.status = 'publish'
            })
          }
          productProperties.push(productSelect)
        }
      })
    } else if (filtered.typeFilter === 10) {
      selectedRows.data.forEach((Selected) => {
        const rowSelected = displayData.find((row) => row.dataIndex === Selected.index)
        const dataRow = rowSelected.data[0]
        productList.push(dataRow)
      })
      productList.forEach((id) => {
        const productSelect = data.find((product) => product.id === id)
        if (productSelect) {
          if (typeProducts.includes(productSelect.type) && productSelect.pendingChanges) {
            productProperties.push(productSelect.id)
          } else if (productSelect.type === 'variable') {
            if (productSelect.pendingChanges) {
              productProperties.push(productSelect.id)
            }
            productSelect.variations.forEach((variation) => {
              if (variation.pendingChanges) {
                productProperties.push(variation.id)
              }
            })
          }
        }
      })
    }
    if (typeSelected === 0) {
      showDialogAlert(
        true,
        `Aprobación de productos`,
        `¿Está seguro que desea aprobar ${productList.length || 1} productos?`,
        'Aceptar',
        'Cancelar',
        false,
        () => {
          if (productProperties.length === 0 && filtered.typeFilter === 2) {
            approveProducts(productList, typeSelected)
          } else if (filtered.typeFilter === 2) {
            approveProducts(productProperties, typeSelected)
          } else if (productProperties.length > 0 && filtered.typeFilter === 10) {
            approveProductsPending(productProperties)
          }
        }
      )
    } else {
      showDialogAlert(
        true,
        `Rechazar productos`,
        null,
        'Aceptar',
        'Cancelar',
        false,
        () => {
          if (document.querySelector('#description').value !== '') {
            if (
              rejectPendingChanges(
                filtered.typeFilter === 2 ? productList : productProperties,
                document.querySelector('#description').value
              )
            ) {
              return false
            }
          } else {
            showDialogAlert(
              true,
              `La observacion es obligatoria`,
              'por favor ingrese la observacion del rechazo',
              'Aceptar',
              '',
              false,
              () => {
                setTypeAction(1)
                if (idProduct) {
                  handleChangeSelectAccion(e, null, null, idProduct, 1)
                } else {
                  handleChangeSelectAccion(e, selectedRows, displayData, null, 1)
                }
              }
            )
            return false
          }
          return true
        },
        null,
        true,

        <>
          <Typography variant="h6">
            {`¿Está seguro que desea rechazar ${
              filtered.typeFilter === 2 ? productList.length : productProperties.length
            } productos?`}
          </Typography>
          <Typography variant="h6">Escribe el por que:</Typography>
          <FormGroup>
            <TextareaAutosize
              id="description"
              inputProps={{ maxLength: 250 }}
              maxRows={3}
              className={classes.descriptionInfoBasic}
            />
          </FormGroup>
          <Typography variant="body1">Recuerda que son máximo 250 caracteres</Typography>
        </>,
        null
      )
    }
    return true
  }

  useEffect(() => {
    setFiltersApi()
  }, [])

  const productsToDelete = async (productsList) => {
    setIsLoading(() => true)
    deleteById(productsList, idSeller)
      .then((response) => {
        setIsLoading(() => false)
        setFiltersApi(filters, 2, pagination.perPage, pagination.page)
        showDialogAlert(true, '¡Advertencia!', response.message, 'Aceptar', '', false)
      })
      .catch(() => {
        setIsLoading(() => false)
        showDialogAlert(
          true,
          '¡Advertencia!',
          `No se eliminaron todo los productos seleccionados`,
          'Cerrar'
        )
      })
  }
  const onClickProduct = (productId) => {
    setIsModified(filtered.typeFilter !== 2 && true)
    history.push(`${routesLink.adminEditProduct}/${productId}/${idSeller}`)
  }

  const returnToStores = () => {
    history.push(`${routesLink.stores}`)
  }

  const changePageTable = (paginationPage) => {
    let pageInt = Number(paginationPage) + 1
    if (paginationPage === 1 && paginationPage === pagination.page) {
      pageInt = 1
    }
    setPagination({
      ...pagination,
      page: pageInt - 1,
      prevPage: pageInt - 1,
      nextPage: pageInt + 1,
    })
    if (filtered.typeFilter === 10) {
      pendingApprovals(pagination.perPage, pageInt)
    } else if (filtered.typeFilter !== '') {
      setFiltersApi(filters, filtered.typeFilter, pagination.perPage, pageInt)
    }
  }

  const changePerPageTable = (value) => {
    setPagination({ ...pagination, perPage: value })
    if (filtered.typeFilter !== '') {
      setFiltersApi(filters, filtered.typeFilter, value, pagination.page)
    }
  }

  const changeLabelFilter = () => {
    let label = null
    if (filtered.typeFilter === 2) {
      label = 'FECHA DE CREACIÓN'
    }
    if (filtered.typeFilter === 10) {
      label = 'FECHA DE EDICIÓN'
    }
    return label
  }
  const changeNameFilter = () => {
    let name = null
    if (filtered.typeFilter === 2) {
      name = 'CreatedOn'
    }
    if (filtered.typeFilter === 10) {
      name = 'LastModifiedOn'
    }
    return name
  }
  const changeData = (item) => {
    if (filtered.typeFilter === 2) {
      return item.dateCreated?.split('T', 1)
    }
    if (filtered.typeFilter === 10) {
      return item.dateModified
        ? item.dateModified?.split('T', 1)
        : item.pendingChanges?.dateModified?.split('T', 1)
    }
  }

  const handleChangeVisibility = async (event, productId) => {
    event.preventDefault()
    setIsLoading(() => true)
    const model = data.find((product) => product.id === productId)
    const updateProduct = { ...model, catalogVisibility: event.target.checked }
    try {
      const result = await axios({
        url: `${MICROSERVICES_PRODUCTS}/${productId}?sellerIdRequest=${idSeller}`,
        method: 'PUT',
        data: updateProduct,
      })
      if (result) setIsLoading(() => false)
      if (result.status === 200 || result.status === 201) {
        showDialogAlert(
          true,
          '¡Cambio exitoso!',
          `El producto se ha de ${
            result.data?.data?.catalogVisibility ? 'visualizar' : 'ocultar'
          } en el catálago`,
          'Cerrar',
          '',
          true
        )
        const newData = [...data]
        const found = newData.find((product) => product.id === productId)
        found.catalogVisibility = result.data.data.catalogVisibility
        setData(newData)
      }
    } catch (error) {
      setIsLoading(() => false)
      showDialogAlert(
        true,
        '¡Error!',
        `Hubo un error al actualizar la visibilidad del producto en el catálago`,
        'Cerrar'
      )
    }
    return true
  }

  const columns = [
    {
      name: 'id',
      options: {
        display: false,
        download: false,
      },
    },
    {
      options: {
        customBodyRender: (value, tableMeta) => {
          return tableMeta.rowData[1][0]?.src && tableMeta.rowData[1][0]?.src !== 'string' ? (
            <>
              <ImageTooltip image={tableMeta.rowData[1][0].src} showImage />
            </>
          ) : (
            <DefaultImage />
          )
        },
      },
    },
    {
      name: 'name',
      label: 'NOMBRE PRODUCTO',
      options: {
        customHeadLabelRender: () => {
          return (
            <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>NOMBRE PRODUCTO</th>
          )
        },
        customBodyRender: (value, tableMeta) => {
          return (
            <Link
              href={`${routesLink.adminEditProduct}/${tableMeta.rowData[0]}/${idSeller}`}
              color="secondary"
              onClick={(event) => {
                event.preventDefault()
                history.push(`${routesLink.adminEditProduct}/${tableMeta.rowData[0]}/${idSeller}`)
              }}
            >
              <Typography variant="subtitle1">{value}</Typography>
            </Link>
          )
        },
      },
    },
    {
      name: changeNameFilter(),
      label: changeLabelFilter(),
      options: {
        customHeadLabelRender: () => {
          return (
            <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>
              {changeLabelFilter()}
            </th>
          )
        },
      },
    },
    {
      name: 'sku',
      label: 'SKU',
      options: {
        customHeadLabelRender: () => {
          return <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>SKU</th>
        },
      },
    },
    {
      name: 'type',
      label: 'VARIACIÓN',
      options: {
        customHeadLabelRender: () => {
          return <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>VARIACIÓN</th>
        },
      },
    },
    {
      name: 'price',
      label: 'PRECIO',
      options: {
        customHeadLabelRender: () => {
          return <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>PRECIO</th>
        },
        customBodyRender: (value, tableMeta) => {
          const productType = tableMeta.rowData[3]
          return (
            <>{productType === 'variable' ? <span>--</span> : `$${convertToCurrencyCop(value)}`}</>
          )
        },
      },
    },
    {
      name: 'salePrice',
      label: 'PRECIO PROMOCIÓN',
      options: {
        customHeadLabelRender: () => {
          return (
            <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>PRECIO PROMOCIÓN</th>
          )
        },
        customBodyRender: (value, tableMeta) => {
          const productType = tableMeta.rowData[3]
          return (
            <>{productType === 'variable' ? <span>--</span> : `$${convertToCurrencyCop(value)}`}</>
          )
        },
      },
    },
    {
      name: 'status',
      label: 'VISIBLE',
      options: {
        customHeadLabelRender: () => {
          return <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>VISIBLE</th>
        },
        customBodyRender: (value, tableMeta) => {
          const productId = tableMeta.rowData[0]
          const productIsVisible = tableMeta.rowData[8]
          const status = tableMeta.rowData[9]
          return (
            <>
              <FormControlLabel
                className={classes.switchVisibility}
                control={
                  <Switch
                    onChange={(target) => handleChangeVisibility(target, productId)}
                    checked={productIsVisible === 'Si'}
                    disabled={status === 'No' || filtered.typeFilter === 10}
                    name="isVisible"
                    color="primary"
                    size="small"
                  />
                }
              />
            </>
          )
        },
      },
    },

    {
      name: '',
      label: '',
      options: {
        download: false,
        filter: false,
        // display: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <Tooltip title="ver Producto">
                <IconButton
                  className={classes.btnEdit}
                  onClick={(event) => {
                    event.preventDefault()
                    onClickProduct(tableMeta.rowData[0])
                  }}
                >
                  <EyeIcon />
                </IconButton>
              </Tooltip>
              {filtered.typeFilter !== 11 && (
                <Tooltip title="Aprobar producto">
                  <IconButton
                    className={classes.btnEdit}
                    disabled={allRowsSelectedContext.length > 0}
                    onClick={(event) => {
                      event.preventDefault()
                      setTypeAction(0)
                      handleChangeSelectAccion(event, null, null, tableMeta.rowData[0], 0)
                    }}
                  >
                    <CheckIcon />
                  </IconButton>
                </Tooltip>
              )}

              {filtered.typeFilter === 2 ? (
                <Tooltip title="Eliminar">
                  <IconButton
                    className={classes.btnDelete}
                    onClick={(event) => {
                      event.preventDefault()
                      productsToDelete(tableMeta.rowData[0])
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              ) : null}
              {filtered.typeFilter === 10 ? (
                <Tooltip title="Rechazar producto">
                  <IconButton
                    className={classes.btnDelete}
                    disabled={allRowsSelectedContext.length > 0}
                    onClick={(event) => {
                      event.preventDefault()
                      setTypeAction(1)
                      handleChangeSelectAccion(event, null, null, tableMeta.rowData[0], 1)
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                </Tooltip>
              ) : null}
            </>
          )
        },
      },
    },
  ]
  const showMenuItemsStatu = () => {
    const optionsAction = [
      <option value="">Seleccione</option>,
      filtered.typeFilter !== 11 && <option value="0">Aprobar productos</option>,
      filtered.typeFilter === 10 && <option value="1">Rechazar productos</option>,
      filtered.typeFilter === 2 && <option value="2">Eliminar productos</option>,
      filtered.typeFilter === 11 && <option value="3">Activar visibilidad</option>,
      filtered.typeFilter === 11 && <option value="4">Desactivar visibilidad</option>,
      <option value="5">Activar transportadora</option>,
      <option value="6">Desactivar transportadora</option>,
    ]

    const actionsMenu = (
      <>
        <TextField
          id="actions"
          select
          className={classes.actionsButton}
          onChange={(e) => {
            e.preventDefault()
            setTypeAction(Number(e.target.value))
          }}
          SelectProps={{
            native: true,
          }}
          variant="outlined"
        >
          {optionsAction}
        </TextField>
      </>
    )
    return <>{actionsMenu}</>
  }
  const handlerSearch = async (text) => {
    if (text === '') return false
    setFiltersApi(filtered, filtered.typeFilter, pagination.perPage, 1, text)
    setPagination(pagination)
    return true
  }
  const optionsCopy = {
    customToolbarSelect: (selectedRows, displayData) => {
      return (
        <Grid container className={classes.toolBarSelect}>
          {showMenuItemsStatu()}
          <Button
            color="primary"
            variant="contained"
            className={classes.btnApply}
            onClick={(e) => {
              handleChangeSelectAccion(e, selectedRows, displayData)
            }}
          >
            Aplicar acción
          </Button>
        </Grid>
      )
    },
  }
  const options = {
    ...optionsCopy,
    ...getOptionsTables(
      pagination,
      total,
      isLoading,
      setAllRowsSelectedContext,
      setTypeAction,
      allRowsSelectedContext,
      changePageTable,
      changePerPageTable
    ),
  }

  return (
    <>
      <Grid container justify="space-between">
        <Typography variant="h6">Lista de productos de la tienda:</Typography>
        <Grid className={classes.divButtonsHeader}>
          <Button
            onClick={returnToStores}
            className={classes.btnNewProduct}
            variant="contained"
            color="secondary"
          >
            Regresar a las tiendas
          </Button>
        </Grid>
      </Grid>
      <Grid container direction="column" className={classes.divContent}>
        <FiltersProducts
          handlerSearch={handlerSearch}
          setFiltersApi={setFiltersApi}
          filtered={filtered}
          setFiltered={setFiltered}
          pendingApprovals={pendingApprovals}
        />
        <MUIDataTable
          data={data.map((item) => {
            let promoPrice = item.promotions || 0
            if (
              (filtered.typeFilter === 2 || filtered.typeFilter === 11) &&
              item?.promotions?.length > 0
            ) {
              promoPrice =
                item.regularPrice -
                item.promotions.find((promo) => promo.name === PROMOTIONS)?.value
            }
            return [
              item.id,
              item.images,
              item.name,
              changeData(item),
              item.sku,
              item.type,
              item.regularPrice,
              promoPrice,
              item.catalogVisibility ? 'Si' : 'No',
              item.status === 'draft' ? 'No' : 'Si',
            ]
          })}
          columns={columns}
          options={options}
        />

        <BackDrop open={isLoading} />
      </Grid>
    </>
  )
}
