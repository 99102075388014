/* eslint-disable no-use-before-define */
import React, { useState, useContext } from 'react'

import DateFnsUtils from '@date-io/date-fns'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputBase,
  List,
  ListItem,
  ListItemText,
  Paper,
  Popover,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import DateRangeIcon from '@material-ui/icons/DateRange'
import LocalAtmIcon from '@material-ui/icons/LocalAtm'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import PersonIcon from '@material-ui/icons/Person'
import SearchIcon from '@material-ui/icons/Search'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import StorageIcon from '@material-ui/icons/Storage'
import Pagination from '@material-ui/lab/Pagination'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import axios from 'axios'
import deLocale from 'date-fns/locale/es'
import moment from 'moment'
import PropTypes from 'prop-types'

import { API_ORDERS } from 'constants/urls'
import useAuthContext from 'contextApi/AuthContext'
import { translateNameToFilterId } from 'utils/filterState'

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: 'white',
    marginTop: 15,
  },
  divBuscador: {
    border: `1px solid ${theme.palette.primary.main}`,
    display: 'flex',
    padding: '2px 4px',
    width: 406,
  },
  divFiltros: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexGrow: 1,
    height: 330,
  },
  iconButton: {
    padding: 10,
    fontFamily: 'Red Hat Display Bold',
  },
  input: {
    flex: 1,
    marginLeft: theme.spacing(1),
    fontSize: '14px',
    fontFamily: 'Red Hat Display Regular',
  },
  labelIconTab: {
    minHeight: '55px',
    paddingTop: '9px',
  },
  lista: {
    padding: '0px 0px 4px 0px',
  },
  opcionesFiltro: {
    backgroundColor: 'white',
    maxWidth: '34%',
  },
  paginacion: {
    display: 'flex',
    justifyContent: 'center',
  },
  wrapperTab: {
    alignItems: 'center',
    display: 'grid',
    gap: '15px',
    gridTemplateColumns: '20px 1fr',
    justifyItems: 'flex-start',
    width: '100%',
  },
  tabs: {
    backgroundColor: '#EFEFEF',
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  tabPanel: {
    backgroundColor: '#D7D7D7',
    maxWidth: '245px',
    width: '100%',
  },
}))
function TabPanel(props) {
  const { children, value, index, ...other } = props
  const classes = useStyles()

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className={classes.tabPanel}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

TabPanel.defaultProps = {
  children: '',
}

const opcionesFiltro = [
  {
    icon: <ShoppingCartIcon />,
    label: 'Pedido',
  },
  {
    icon: <PersonIcon />,
    label: 'Cliente',
  },
  {
    icon: <LocalAtmIcon />,
    label: 'Pago',
  },
  {
    icon: <DateRangeIcon />,
    label: 'Fecha',
  },
  {
    icon: <StorageIcon />,
    label: 'Producto',
  },
  {
    icon: <LocalShippingIcon />,
    label: 'Detalle d...',
  },
]

const detallesDeEnvio = [
  { name: 'No ha sido enviado en menos de 24 horas' },
  { name: 'No ha sido enviado en más de 24 horas' },
]

const pageSize = 6
const defaultPage = 1

export default function SearchPedidos({ setResults, placeholder }) {
  const classes = useStyles()
  const { currentFilterOrders } = useContext(useAuthContext)
  const [valorABuscar, setValorABuscar] = useState('')
  const [selectedOpcionFiltro, setSelectedOpcionFiltro] = useState(0) // Valor de selectedOpcionFiltro: 0:Pedido, 1:Cliente, 2:Pago, 3:Fecha, 4:Producto, 5:Detalle envío
  const [isLoading, setIsLoading] = useState(false)
  const [isButtonSearchBlocked, setIsButtonSearchBlocked] = useState(false)
  // Pedido
  const [isClickBusquedaxPedido, setIsClickBusquedaxPedido] = useState(false)
  const [resultsxPedido, setResultsxPedido] = useState([])
  const [selectedListPedido, setSelectedListPedido] = useState('')
  const [notificacionTabPedido, setNotificacionTabPedido] = useState(
    'Por favor, escoge un criterio de búsqueda y empieza escribiendo.'
  )
  // Cliente
  const [isClickBusquedaxCliente, setIsClickBusquedaxCliente] = useState(false)
  const [resultsxCliente, setResultsxCliente] = useState([])
  const [selectedListCliente, setSelectedListCliente] = useState('')
  const [notificacionTabCliente, setNotificacionTabCliente] = useState(
    'Por favor, escoge un criterio de búsqueda y empieza escribiendo.'
  )
  // Pago
  const [metodosPago, setMetodosPago] = useState([])
  const [isClickBusquedaxMetodoPago, setIsClickBusquedaxMetodoPago] = useState(true)
  const [selectedMetodoPago, setSelectedMetodoPago] = useState('')
  const [notificacionTabMetodoPago, setNotificacionTabMetodoPago] = useState('')
  // Fecha
  const [selectedFechaDesde, setSelectedFechaDesde] = useState(new Date())
  const [selectedFechaHasta, setSelectedFechaHasta] = useState(new Date())
  const [isButtonFechasBlocked, setIsButtonFechasBlocked] = useState(false)
  const [notificacionTabFecha, setNotificacionTabFecha] = useState('')
  // Producto
  const [isClickBusquedaxProducto, setIsClickBusquedaxProducto] = useState(false)
  const [resultsxProducto, setResultsxProducto] = useState([])
  const [selectedListProducto, setSelectedListProducto] = useState('')
  const [notificacionTabProducto, setNotificacionTabProducto] = useState(
    'Por favor escoge un criterio de búsqueda y empieza escribiendo.'
  )
  // Detalle de envío
  const [selectedDetalleDeEnvio, setSelectedDetalleDeEnvio] = useState('')
  const [isClickBusquedaxDetalleEnvio, setIsClickBusquedaxDetalleEnvio] = useState(true)
  const [notificacionTabDetalleDeEnvio, setNotificacionTabDetalleDeEnvio] = useState('')

  const mostrarLoadingYBloquearBoton = (estado) => {
    setIsLoading(estado)
    setIsButtonSearchBlocked(estado)
  }

  const consultaxPedido = async () => {
    mostrarLoadingYBloquearBoton(true)
    setIsClickBusquedaxPedido(true)

    await axios
      .post(`${API_ORDERS}/OrdersBySearchEngineList/${pageSize}/${defaultPage}/`, {
        type: 1,
        orderFilterTypeId: translateNameToFilterId(currentFilterOrders),
        filter: valorABuscar.toString(),
      })
      .then((response) => {
        if (response.status === 200) {
          setResultsxPedido(response.data.data)
          if (response.data.data.length === 0) {
            setIsClickBusquedaxPedido(false) // Para mostrar la notificación
            setNotificacionTabPedido('El número de pedido introducido no existe')
          }
        } else {
          setIsClickBusquedaxPedido(false) // Para mostrar la notificación
          setNotificacionTabPedido('El número de pedido introducido no existe')
        }
        mostrarLoadingYBloquearBoton(false)
      })
      .catch((e) => {
        setIsClickBusquedaxPedido(false) // Para mostrar la notificación
        setNotificacionTabPedido(
          'Ocurrió un error al consultar los pedidos, intente nuevamente o contacte al administrador'
        )
        mostrarLoadingYBloquearBoton(false)
      })
  }

  const handleChangeListPedido = (event, itemPedido) => {
    setSelectedListPedido(itemPedido.pedido)
    setResults([itemPedido])
  }

  const consultaxCliente = async () => {
    mostrarLoadingYBloquearBoton(true)
    setIsClickBusquedaxCliente(true)
    await axios
      .post(`${API_ORDERS}/OrdersBySearchEngineList/${pageSize}/${defaultPage}/`, {
        type: 2,
        filter: valorABuscar.toString(),
      })
      .then((response) => {
        if (response.status === 200) {
          setResultsxCliente(response.data.data)
          if (response.data.data.length === 0) {
            setIsClickBusquedaxCliente(false) // Para mostrar la notificación
            setNotificacionTabCliente(
              'El cliente introducido no existe o no tiene pedidos asociados'
            )
          }
        } else {
          setIsClickBusquedaxCliente(false) // Para mostrar la notificación
          setNotificacionTabCliente('El cliente introducido no existe o no tiene pedidos asociados')
        }
        mostrarLoadingYBloquearBoton(false)
      })
      .catch((e) => {
        setIsClickBusquedaxCliente(false) // Para mostrar la notificación
        setNotificacionTabCliente(
          'Ocurrió un error al consultar los pedidos, intente nuevamente o contacte al administrador'
        )
        mostrarLoadingYBloquearBoton(false)
      })
  }

  const handleChangeListCliente = (event, itemPedido) => {
    setSelectedListCliente(itemPedido.pedido)
    setResults([itemPedido])
  }

  const handleChangeMetodoPago = async (event, name) => {
    setSelectedMetodoPago(name)

    mostrarLoadingYBloquearBoton(true)
    setIsClickBusquedaxMetodoPago(true)
    await axios
      .post(`${API_ORDERS}/OrdersBySearchEngineList/1000/1/`, {
        type: 3,
        filter: name.toString(),
      })
      .then((response) => {
        if (response.status === 200) {
          setResults(response.data.data)
        } else {
          setIsClickBusquedaxMetodoPago(false) // Para mostrar la notificación
          setNotificacionTabMetodoPago('El pago seleccionado no tiene pedidos asociados')
        }
        mostrarLoadingYBloquearBoton(false)
      })
      .catch((e) => {
        setIsClickBusquedaxMetodoPago(false) // Para mostrar la notificación
        setNotificacionTabMetodoPago(
          'Ocurrió un error al consultar los pedidos, intente nuevamente o contacte al administrador'
        )
        mostrarLoadingYBloquearBoton(false)
      })
  }

  const handleClickBusquedaxFecha = async () => {
    mostrarLoadingYBloquearBoton(true)
    setIsButtonFechasBlocked(true)
    setNotificacionTabFecha('')

    const dateDesde = moment(selectedFechaDesde)
    const dateHasta = moment(selectedFechaHasta)

    if (dateDesde > dateHasta) {
      mostrarLoadingYBloquearBoton(false)
      setIsButtonFechasBlocked(false)
      return setNotificacionTabFecha('La fecha desde debe ser menor a la fecha hasta')
    }

    await axios
      .post(`${API_ORDERS}/OrdersBySearchEngineList/${pageSize}/${defaultPage}/`, {
        type: 4,
        filter: {
          startDate: '25 Jun 2020',
          endDate: '26 Jun 2020',
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setResults(response.data.data)
        } else {
          setNotificacionTabFecha('El rango de fechas seleccionado no tiene pedidos asociados')
        }
        mostrarLoadingYBloquearBoton(false)
        setIsButtonFechasBlocked(false)
      })
      .catch((e) => {
        setIsClickBusquedaxMetodoPago(false) // Para mostrar la notificación
        setNotificacionTabFecha(
          'Ocurrió un error al consultar los pedidos, intente nuevamente o contacte al administrador'
        )
        mostrarLoadingYBloquearBoton(false)
      })
    return true
  }

  const consultaxProducto = async () => {
    mostrarLoadingYBloquearBoton(true)
    setIsClickBusquedaxProducto(true)

    await axios
      .post(`${API_ORDERS}/OrdersBySearchEngineList/${pageSize}/${defaultPage}/`, {
        type: 5,
        filter: valorABuscar.toString(),
      })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.data.length === 0) {
            setIsClickBusquedaxProducto(false) // Para mostrar la notificación
            setNotificacionTabProducto(
              'El producto introducido no existe o no tiene pedidos asociados'
            )
          } else {
            setResultsxProducto(response.data.data)
          }
        } else {
          setIsClickBusquedaxProducto(false) // Para mostrar la notificación
          setNotificacionTabProducto(
            'El producto introducido no existe o no tiene pedidos asociados'
          )
        }
        mostrarLoadingYBloquearBoton(false)
      })
      .catch((e) => {
        setIsClickBusquedaxProducto(false) // Para mostrar la notificación
        setNotificacionTabProducto(
          'Ocurrió un error al consultar los pedidos, intente nuevamente o contacte al administrador'
        )
        mostrarLoadingYBloquearBoton(false)
      })
  }

  const handleChangeListProducto = (event, itemPedido) => {
    setSelectedListProducto(itemPedido.pedido)
    setResults([itemPedido])
  }

  const handleChangeDetalleEnvio = async (event, name) => {
    setSelectedDetalleDeEnvio(name)

    mostrarLoadingYBloquearBoton(true)
    setIsClickBusquedaxDetalleEnvio(true)

    const filterValue = name === 'No ha sido enviado en más de 24 horas' ? 'true' : 'false'

    await axios
      .post(`${API_ORDERS}/OrdersBySearchEngineList/1000/1/`, {
        type: 6,
        filter: filterValue,
      })
      .then((response) => {
        if (response.status === 200) {
          setResults(response.data.data)
        } else {
          setIsClickBusquedaxDetalleEnvio(false) // Para mostrar la notificación
          setNotificacionTabDetalleDeEnvio('El pago seleccionado no tiene pedidos asociados')
        }
        mostrarLoadingYBloquearBoton(false)
      })
      .catch((e) => {
        setIsClickBusquedaxDetalleEnvio(false) // Para mostrar la notificación
        setNotificacionTabDetalleDeEnvio(
          'Ocurrió un error al consultar los pedidos, intente nuevamente o contacte al administrador'
        )
        mostrarLoadingYBloquearBoton(false)
      })
  }

  const handleChangeTab = async (event, index) => {
    setSelectedOpcionFiltro(index)
    setAnchorEl(null)
    // Si selecciona metodos de pago osea index 2, consultar las opciones desde la api

    if (index === 2) {
      mostrarLoadingYBloquearBoton(true)

      await axios
        .get(`${API_ORDERS}/AllPaymetnGatewayList/`)
        .then((response) => {
          if (response.status === 200) {
            setMetodosPago(response.data)
          } else {
            setIsClickBusquedaxMetodoPago(false) // Para mostrar la notificación
            setNotificacionTabMetodoPago(
              'No se encontraron métodos de pago, consulte de nuevo o contacte al administrador'
            )
          }
          mostrarLoadingYBloquearBoton(false)
        })
        .catch((e) => {
          setIsClickBusquedaxMetodoPago(false) // Para mostrar la notificación
          setNotificacionTabMetodoPago(
            'Ocurrió un error al consultar los métodos de pago, intente nuevamente o contacte al administrador'
          )
          mostrarLoadingYBloquearBoton(false)
        })
    }
  }

  const handleSubmit = () => {
    if (valorABuscar === '') return console.log('Debe ingresar un valor a buscar')
    switch (selectedOpcionFiltro) {
      // Valor de selectedOpcionFiltro: 0:Pedido, 1:Cliente, 2:Pago, 3:Fecha, 4:Producto, 5:Detalle envío
      case 0:
        return consultaxPedido()
      case 1:
        return consultaxCliente()
      case 4:
        return consultaxProducto()
      default:
        return console.log('Default del switch')
    }
  }

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <Grid className={classes.container}>
      <Paper
        onClick={handleClick}
        onKeyUp={(e) => {
          e.preventDefault()
          if (e.key === 'Enter' && valorABuscar !== '') {
            handleSubmit()
            handleClick(e)
          }
        }}
        className={classes.divBuscador}
      >
        <InputBase
          autoFocus
          className={classes.input}
          placeholder={`${placeholder || 'Número de orden, cliente u otro criterio'}`}
          onChange={(event) => {
            setValorABuscar(event.target.value.trimStart())
            setAnchorEl(null)
          }}
          value={valorABuscar}
        />
        {isLoading && (
          <CircularProgress
            sizstyle={{ marginLeft: 15, position: 'relative', top: 4 }}
            e={24}
            style={{ marginLeft: 15, position: 'relative', top: 4 }}
          />
        )}
        <Button
          color="primary"
          size="small"
          className={classes.iconButton}
          onClick={handleSubmit}
          disabled={isButtonSearchBlocked}
          startIcon={<SearchIcon />}
        >
          Buscar
        </Button>
      </Paper>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={classes.divFiltros}>
          <Tabs
            aria-label="Vertical tabs example"
            className={classes.tabs}
            indicatorColor="primary"
            onChange={(event, index) => handleChangeTab(event, index)}
            orientation="vertical"
            variant="scrollable"
            value={selectedOpcionFiltro}
          >
            {opcionesFiltro.map((option) => {
              return (
                <Tab
                  classes={{ labelIcon: classes.labelIconTab, wrapper: classes.wrapperTab }}
                  icon={option.icon}
                  key={option.label}
                  label={option.label}
                />
              )
            })}
          </Tabs>
          <TabPanel value={selectedOpcionFiltro} index={0}>
            {isClickBusquedaxPedido ? (
              <>
                <List classes={{ root: classes.lista }}>
                  {resultsxPedido
                    ?.sort(() => Math.random() - 0.5)
                    .map((item) => (
                      <ListItem
                        button
                        key={item.pedido}
                        selected={selectedListProducto === item.pedido}
                        onClick={(event) => handleChangeListPedido(event, item)}
                      >
                        <ListItemText primary={item.pedido} />
                      </ListItem>
                    ))}
                </List>
                <Pagination classes={{ root: classes.paginacion }} count={3} size="small" />
              </>
            ) : (
              <Box p={2}>
                <Typography>{notificacionTabPedido}</Typography>
              </Box>
            )}
          </TabPanel>
          <TabPanel value={selectedOpcionFiltro} index={1}>
            {isClickBusquedaxCliente ? (
              <>
                <List classes={{ root: classes.lista }}>
                  {resultsxCliente
                    ?.sort(() => Math.random() - 0.5)
                    .map((item) => (
                      <ListItem
                        button
                        key={item.pedido}
                        selected={selectedListCliente === item.pedido}
                        onClick={(event) => handleChangeListCliente(event, item)}
                      >
                        <ListItemText primary={item.pedido} />
                      </ListItem>
                    ))}
                </List>
                <Pagination classes={{ root: classes.paginacion }} count={3} size="small" />
              </>
            ) : (
              <Box p={2}>
                <Typography>{notificacionTabCliente}</Typography>
              </Box>
            )}
          </TabPanel>
          <TabPanel value={selectedOpcionFiltro} index={2}>
            {isClickBusquedaxMetodoPago ? (
              <List>
                {metodosPago.map((metodo) => (
                  <ListItem
                    button
                    key={metodo.method_title}
                    selected={selectedMetodoPago === metodo.method_title}
                    onClick={(event) => handleChangeMetodoPago(event, metodo.method_title)}
                  >
                    <ListItemText primary={metodo.method_title} />
                  </ListItem>
                ))}
              </List>
            ) : (
              <Box p={2}>
                <Typography>{notificacionTabMetodoPago}</Typography>
              </Box>
            )}
          </TabPanel>
          <TabPanel value={selectedOpcionFiltro} index={3}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
              <Grid container justify="space-around">
                <KeyboardDatePicker
                  autoOk
                  disableToolbar
                  format="dd/MM/yyyy"
                  KeyboardButtonProps={{
                    'aria-label': 'fecha desde',
                  }}
                  label="Desde"
                  margin="normal"
                  onChange={(date) => setSelectedFechaDesde(date)}
                  value={selectedFechaDesde}
                  variant="inline"
                />

                <KeyboardDatePicker
                  autoOk
                  disableToolbar
                  format="dd/MM/yyyy"
                  KeyboardButtonProps={{
                    'aria-label': 'fecha hasta',
                  }}
                  label="Hasta"
                  margin="normal"
                  onChange={(date) => setSelectedFechaHasta(date)}
                  value={selectedFechaHasta}
                  variant="inline"
                />

                <Button
                  color="primary"
                  disabled={isButtonFechasBlocked}
                  onClick={handleClickBusquedaxFecha}
                  size="small"
                  variant="contained"
                >
                  Buscar
                </Button>

                {notificacionTabFecha && (
                  <Box p={2}>
                    <Typography>{notificacionTabFecha}</Typography>
                  </Box>
                )}
              </Grid>
            </MuiPickersUtilsProvider>
          </TabPanel>
          <TabPanel value={selectedOpcionFiltro} index={4}>
            {isClickBusquedaxProducto ? (
              <>
                <List classes={{ root: classes.lista }}>
                  {resultsxProducto
                    ?.sort(() => Math.random() - 0.5)
                    .map((item) => (
                      <ListItem
                        button
                        key={item.pedido}
                        selected={selectedListPedido === item.pedido}
                        onClick={(event) => handleChangeListProducto(event, item)}
                      >
                        <ListItemText primary={item.pedido} />
                      </ListItem>
                    ))}
                </List>
                <Pagination classes={{ root: classes.paginacion }} count={3} size="small" />
              </>
            ) : (
              <Box p={2}>
                <Typography>{notificacionTabProducto}</Typography>
              </Box>
            )}
          </TabPanel>
          <TabPanel value={selectedOpcionFiltro} index={5}>
            {isClickBusquedaxDetalleEnvio ? (
              <List>
                {detallesDeEnvio.map((opcion) => (
                  <ListItem
                    button
                    key={opcion.name}
                    selected={selectedDetalleDeEnvio === opcion.name}
                    onClick={(event) => handleChangeDetalleEnvio(event, opcion.name)}
                  >
                    <ListItemText primary={opcion.name} />
                  </ListItem>
                ))}
              </List>
            ) : (
              <Box p={2}>
                <Typography>{notificacionTabDetalleDeEnvio}</Typography>
              </Box>
            )}
          </TabPanel>
        </div>
      </Popover>
    </Grid>
  )
}

SearchPedidos.propTypes = {
  setResults: PropTypes.func.isRequired,
}
